import { EntitySelectorsFactory } from '@ngrx/data'
import { PersonAttribute } from './person-attribute.model'

export const personAttributeSelector =
	new EntitySelectorsFactory().create<PersonAttribute>('PersonAttribute')

/**
 * Returns a string to be used as id of PersonAttribute for ngrx selectId
 * @param {PersonAttribute} personAttribute
 * @returns {string} - identifier for entity (id + personId)
 */
export const selectPersonAttributeStoreId = (
	personAttribute: PersonAttribute
) => `${personAttribute.personId}-${personAttribute.id}`
