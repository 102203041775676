export enum PersonAttributeId {
	SuperUserTechnical = 1, //Pääkäyttäjä_tekninen
	SuperUserAdminstration = 2, //Pääkäyttäjä_hallinto
}

export enum PersonRecordingMethodId {
	ClockIn = 9000,
	Calendar = 9001,
}

/**
 * List of personAttributeId's that allow access to
 * management page
 */
export const personAttributeAllowManagement = [
	PersonAttributeId.SuperUserTechnical,
	PersonAttributeId.SuperUserAdminstration,
]
