import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

/**
 * Loading spinner component. Spans the whole available space and has grey opaque backdrop.
 */
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	selector: 'kk-loading-spinner',
	styleUrls: ['./loading.component.scss'],
	template: `
		<div
			*ngIf="visible"
			class="kk-loading-spinner d-flex w-100 h-100 align-items-center justify-content-center position-absolute top-0 start-0 bg-black bg-opacity-10"
			data-testid="loading-spinner"
		>
			<div
				class="background d-flex justify-content-center align-items-center position-absolute mw-100 mh-100 bg-white rounded-3 shadow"
			>
				<i class="fa-light fa-spinner fa-spin fa-2xl" role="status"></i>
				<span i18n class="visually-hidden sr-only">Ladataan</span>
			</div>
		</div>
	`,
	imports: [CommonModule],
})
export class LoadingComponent {
	@Input() visible = false
}
