import { EntityOp, ofEntityType } from '@ngrx/data'
import { inject } from '@angular/core'
import { filter, tap, throttleTime } from 'rxjs'
import { Actions, createEffect } from '@ngrx/effects'
import { EntityName } from '../entity-metadata'
import { ToastService } from './toast.service'

interface ToastMessagePair {
	success: string
	error: string
}

const defaultUpdateMessagePair: ToastMessagePair = {
	success: $localize`Tietojen tallennus onnistui`,
	error: $localize`Tietojen tallennus epäonnistui`,
}

function createEntityActionToastEffect(
	entityName: EntityName,
	updateMessages?: ToastMessagePair,
	addMessages?: ToastMessagePair,
	deleteMessages?: ToastMessagePair
) {
	return createEffect(
		(actions$ = inject(Actions), service = inject(ToastService)) => {
			// Creates key-value-pairs such as "['[Person] @ngrx/data/save/add-one/success', 'Henkilö lisätty onnistuneesti']"
			// prettier-ignore
			const messages = [
				[`[${entityName}] ${EntityOp.SAVE_ADD_ONE_SUCCESS}`, addMessages?.success],
				[`[${entityName}] ${EntityOp.SAVE_ADD_ONE_ERROR}`, addMessages?.error],
				[`[${entityName}] ${EntityOp.SAVE_UPDATE_ONE_SUCCESS}`, updateMessages?.success],
				[`[${entityName}] ${EntityOp.SAVE_UPDATE_ONE_ERROR}`, updateMessages?.error],
				[`[${entityName}] ${EntityOp.SAVE_DELETE_ONE_SUCCESS}`, deleteMessages?.success],
				[`[${entityName}] ${EntityOp.SAVE_DELETE_ONE_ERROR}`, deleteMessages?.error],
			] as [string,string][]

			const messageMap = new Map(messages)

			return actions$.pipe(
				ofEntityType(entityName),
				filter((action) => !!messageMap.get(action.type)),
				throttleTime(600), // Delay for preventing duplicate toasts when multiple actions are dispatched simultaneously (e.g. when splitting events)
				tap((action) => {
					const type = action.type
					const isSuccess = type.includes('success')

					const message = messageMap.get(action.type)

					if (message) {
						isSuccess
							? service.showSuccess(message)
							: service.showError(message)
					}
				})
			)
		},
		{
			dispatch: false,
			functional: true,
		}
	)
}

export const viewCustomizationToastEffect = createEntityActionToastEffect(
	'ViewCustomization',
	defaultUpdateMessagePair
)

export const eventToastEffect = createEntityActionToastEffect(
	'Event',
	defaultUpdateMessagePair,
	defaultUpdateMessagePair,
	{
		success: $localize`Työajan poisto onnistui`,
		error: $localize`Työajan poisto epäonnistui`,
	}
)

export const WorkShiftToastEffect = createEntityActionToastEffect(
	'WorkShift',
	defaultUpdateMessagePair,
	{
		success: $localize`Työvuoron lisäys onnistui`,
		error: $localize`Työvuoron lisäys epäonnistui`,
	},
	{
		success: $localize`Työvuoron poisto onnistui`,
		error: $localize`Työvuoron poisto epäonnistui`,
	}
)

export const personToastEffect = createEntityActionToastEffect(
	'Person',
	defaultUpdateMessagePair,
	{
		success: $localize`Henkilö lisätty onnistuneesti`,
		error: $localize`Henkilön lisäys epäonnistui`,
	},
	{
		success: $localize`Henkilön poisto onnistui`,
		error: $localize`Henkilön poisto epäonnistui`,
	}
)

export const personTemplateToastEffect = createEntityActionToastEffect(
	'PersonTemplate',
	defaultUpdateMessagePair,
	{
		success: $localize`Mallipohja lisätty onnistuneesti`,
		error: $localize`Mallipohjan lisäys epäonnistui`,
	},
	{
		success: $localize`Mallipohjan poisto onnistui`,
		error: $localize`Mallipohjan poisto epäonnistui`,
	}
)

export const worktimeGroupToastEffect = createEntityActionToastEffect(
	'WorktimeGroup',
	defaultUpdateMessagePair,
	{
		success: $localize`Työaikaryhmä lisätty onnistuneesti`,
		error: $localize`Työaikaryhmän lisäys epäonnistui`,
	},
	{
		success: $localize`Työaikaryhmän poisto onnistui`,
		error: $localize`Työaikaryhmän poisto epäonnistui`,
	}
)

export const EventTypeToastEffect = createEntityActionToastEffect(
	'EventType',
	defaultUpdateMessagePair,
	{
		success: $localize`Kirjauslaji lisätty onnistuneesti`,
		error: $localize`Kirjauslajin lisäys epäonnistui`,
	},
	{
		success: $localize`Kirjauslajin poisto onnistui`,
		error: $localize`Kirjauslajin poisto epäonnistui`,
	}
)
