import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Event } from './event.model'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { userFeature } from '../user/user.feature'
import { UserService } from '../entity-services'
import { DateUtils } from '../../utils/date-utils'

@Injectable()
export class EventService extends EntityCollectionServiceBase<Event> {
	private _loadedMonths: {
		personId: number
		year: number
		month: number
	}[] = []

	constructor(
		private http: HttpClient,
		userService: UserService,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('Event', serviceElementsFactory)

		userService.getCurrentUser().subscribe(() => {
			this._loadedMonths = []
		})
	}

	getStampInStatus(): Observable<boolean> {
		const user = this.store.selectSignal(userFeature.selectUser)
		return this.http.get<boolean>(`events/stamp-in-status/` + user()?.id)
	}

	getOpenEvent(): Observable<Event | null> {
		const user = this.store.selectSignal(userFeature.selectUser)
		return this.http.get<Event>(`events/get-open-event/` + user()?.id)
	}

	/**
	 * Loads the events for the given person and month.
	 *
	 * @param {Date} currentDate - The current date for which the events are loaded.
	 * @param {number} personId - The id of the person for which the events are loaded.
	 */
	loadMonthlyEvents(currentDate: Date, personId: number) {
		const year = currentDate.getFullYear()
		const monthIndex = currentDate.getMonth()

		if (
			this._loadedMonths.some(
				(i) =>
					i.personId === personId && i.year === year && i.month === monthIndex
			)
		) {
			return
		}

		this._loadedMonths.push({
			personId: personId,
			year: year,
			month: monthIndex,
		})

		const { startDate, endDate } = this.calculateDateRange(currentDate)

		const queryParams = {
			personId: personId,
			startDate,
			endDate,
		}

		this.getWithQuery(queryParams).subscribe({
			error: () => (this._loadedMonths = []),
		})
	}

	/**
	 * Calculates the date range for the given current date.
	 * The range starts from the 1st day of the previous month and ends on the last day of the next month.
	 *
	 * @param {Date} currentDate - The current date for which the range is calculated.
	 * @returns {Object} An object containing the startDate and endDate in ISO string format.
	 */
	calculateDateRange(currentDate: Date): {
		startDate: string
		endDate: string
	} {
		const previousMonthDate = new Date(currentDate)
		previousMonthDate.setMonth(currentDate.getMonth() - 1)
		const startOfMonth = DateUtils.getFirstDateOfMonth(previousMonthDate)

		const nextMonthDate = new Date(currentDate)
		nextMonthDate.setMonth(currentDate.getMonth() + 1)
		const endOfMonth = DateUtils.getLastDateOfMonth(nextMonthDate)

		return {
			startDate: DateUtils.formatDateWithoutTimezone(startOfMonth),
			endDate: DateUtils.formatDateWithoutTimezone(endOfMonth),
		}
	}

	override clearCache(): void {
		this._loadedMonths = []
		super.clearCache()
	}
}
