import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { User } from "./user.model";

export const userApiActions = createActionGroup({
    source: 'CurrentUser API',
    events: {
        loadUser: emptyProps(),
        userLoadedSuccess: props<{ user: User }>(),
        userLoadedFailure: props<{ error: string }>(),
    },
  });
  