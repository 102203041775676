import {
	worktimeGroupSelector,
	selectWorktimeGroupId,
} from 'src/app/core/ngrx-store/worktime-group/worktime-group.selectors'
import { EntityService, EntityType, entitySelectorFactory } from '../../models'
import { WorktimeGroup } from 'src/app/core/ngrx-store/models'
import { InjectionToken, inject, Injectable } from '@angular/core'
import { WorktimeGroupService } from 'src/app/core/ngrx-store/worktime-group/worktime-group.service'
import { overtimePracticeOptions } from 'src/app/core/ngrx-store/worktime-group/overtime-practice.model'
import {
	EventTypeService,
	WorkShiftService,
} from 'src/app/core/ngrx-store/entity-services'
import { WorktimeGroupCreateNewContentComponent } from './worktime-group-create-new.component'
import {
	checkboxField,
	emptyRow,
	numberField,
	textField,
} from 'src/app/shared/components/form/formly-field'
import { AbstractControl } from '@angular/forms'
import { workshiftSelectionPracticeLabels } from 'src/app/core/ngrx-store/worktime-group/worktime-group.model'
import { dayOfWeekLabels } from 'src/app/core/utils/day-of-week'
import { FormlyFieldConfig } from '@ngx-formly/core'

export function overtimePracticeDefaultValidator(control: AbstractControl) {
	const { overtimePracticeDefault, overtimePracticeAll } = control.value
	if (overtimePracticeAll) {
		return true
	}
	const selectedDefault = overtimePracticeOptions.find(
		(item) => item.value === overtimePracticeDefault
	)
	//check the value of the selected option (true or false)
	return control.value[selectedDefault!.worktimeGroupKey]
}

export const selectWorktimeGroupEntity = entitySelectorFactory(
	worktimeGroupSelector
)

@Injectable({ providedIn: 'root' })
export class WorktimeGroupEntityService extends EntityService<WorktimeGroup> {
	constructor(
		worktimeGroupService: WorktimeGroupService,
		private workShiftService: WorkShiftService,
		private eventTypeService: EventTypeService
	) {
		super(worktimeGroupService, selectWorktimeGroupEntity)

		this.formlyFields = [
			{
				type: 'tabs',
				fieldGroup: [
					{
						props: { label: $localize`Perustiedot` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								className: 'g-col-12',
								fieldGroupClassName: 'grid gap-0',
								fieldGroup: [
									textField('name', $localize`Nimi`, 'g-col-12'),
									textField('description', $localize`Kuvaus`, 'g-col-12'),
								],
							},
							{
								className: 'g-col-12 fw-bold',
								template: `<br>
								<h2>${$localize`Liukumat`}</h2>
								<br>`,
							},
							{
								className: 'g-col-12',
								fieldGroupClassName: 'grid gap-0',
								fieldGroup: [
									{
										key: 'flextimeEnabledForGroup',
										type: 'checkbox',
										className: 'g-col-12',
										props: {
											label: $localize`Liukumasaldot käytössä`,
										},
									},
									{
										key: 'flextimeMaximumBalance',
										type: 'input',
										className: 'g-col-12',
										props: {
											label: $localize`Päivän liukumakatto`,
											addonLeft: {
												text: $localize`minuuttia`,
											},
										},
										expressions: {
											hide: '!model.flextimeEnabledForGroup',
										},
									},
									{
										key: 'flextimeMinimumBalance',
										type: 'input',
										className: 'g-col-12',
										props: {
											label: $localize`Päivän minimiliukumaraja`,
											addonLeft: {
												text: $localize`minuuttia`,
											},
										},
										expressions: {
											hide: '!model.flextimeEnabledForGroup',
										},
									},
									{
										key: 'flextimeIgnoreNegativeChanges',
										type: 'checkbox',
										className: 'g-col-12',
										props: {
											label: $localize`Ei miinuksia liukumasaldoon`,
										},
										expressions: {
											hide: '!model.flextimeEnabledForGroup',
										},
									},
									{
										key: 'flextimeIgnoreEmptyWorkshifts',
										type: 'checkbox',
										className: 'g-col-12',
										props: {
											label: $localize`Ei laskentaa tyhjille päiville`,
										},
										expressions: {
											hide: '!model.flextimeEnabledForGroup | model.flextimeIgnoreNegativeChanges',
										},
									},
								],
							},
							{
								className: 'g-col-12 fw-bold',
								template: `<br>
								<h2>${$localize`Valittavissa olevat ylituntitavat`}</h2>
								<br>`,
							},
							{
								validators: {
									overtimePracticeDefaultValidator: {
										expression: (control: AbstractControl) =>
											overtimePracticeDefaultValidator(control),
										errorPath: 'overtimePracticeDefault',
										message: $localize`Valittu ylituntitapa tulee olla käytössä.`,
									},
								},
								className: 'g-col-12',
								fieldGroupClassName: 'grid gap-0',
								fieldGroup: [
									checkboxField(
										'overtimePracticeAll',
										$localize`Valitse kaikki`
									),
									{
										className: 'g-col-12',
										fieldGroupClassName: 'grid gap-0',
										fieldGroup: overtimePracticeOptions.map(
											({ worktimeGroupKey, label }) => ({
												key: worktimeGroupKey,
												type: 'checkbox',
												className: 'g-col-6',
												props: {
													label,
												},
												expressions: {
													'props.disabled': 'model.overtimePracticeAll',
													hide: (field: FormlyFieldConfig) => {
														if (
															(!field.model.flextimeEnabledForGroup &&
																field.key === 'overtimePracticeFlex') ||
															(field.model.flextimeEnabledForGroup &&
																field.key === 'overtimePracticeHourByHour')
														) {
															field.formControl?.setValue(false)
															return true
														}
														return false
													},
												},
											})
										),
									},
									{
										key: 'overtimePracticeDefault',
										type: 'select',
										className: 'g-col-6',
										props: {
											label: $localize`Ylituntitapaoletus`,
											options: overtimePracticeOptions.map(
												({ value, label }) => ({
													value: value,
													label,
												})
											),
										},
										validation: {
											//Force showing validation state
											show: true,
										},
									},
								],
							},
							{
								className: 'g-col-12 fw-bold',
								template: `<br>
								<h2>${$localize`Työvuoron hakutapojen järjestys`}</h2>
								<br>`,
							},
							{
								key: 'workshiftSelectionOrder',
								type: 'sortable-list',
								className: 'g-col-12',
								props: {
									label: $localize`järjestys`,
									items: Object.entries(workshiftSelectionPracticeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
								},
							},
							checkboxField(
								'revalidateWorkshiftOnPunchOut',
								$localize`Työvuoron haku kirjaukselle uudestaan ulosleimauksen yhteydessä`
							),
							emptyRow,
							{
								key: 'automaticReadyCheck',
								type: 'select',
								className: 'g-col-12',
								props: {
									label: $localize`Kirjausten automaattinen valmismerkintä`,
									options: [
										{ value: null, label: $localize`Ei käytössä` },
										...Object.entries(dayOfWeekLabels).map(([key, label]) => ({
											value: key,
											label,
										})),
									],
								},
							},
							numberField(
								'breakMaxLength',
								$localize`Tauon maksimipituus`,
								$localize`minuuttia`
							),
						],
					},
					{
						props: { label: $localize`Asetukset` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							checkboxField(
								'askWorkshiftOnStampIn',
								$localize`Kysy työvuoroa sisäänleimatessa`,
								`g-col-12 g-col-lg-6`
							),
							checkboxField(
								'askWorkshiftOnStampOut',
								$localize`Kysy työvuoroa ulosleimatessa`,
								`g-col-12 g-col-lg-6`
							),
							checkboxField(
								'askEventTypeOnStampIn',
								$localize`Kysy kirjauslajia sisäänleimatessa`,
								`g-col-12 g-col-lg-6`
							),
							checkboxField(
								'askEventTypeOnStampOut',
								$localize`Kysy kirjauslajia ulosleimatessa`,
								`g-col-12 g-col-lg-6`
							),
						],
					},
					{
						props: { label: $localize`Työvuorot` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								type: 'workshift-table',
								className: 'g-col-12',
								props: {
									items: this.workShiftService.workShiftsForWorktimeGroup$,
								},
								hooks: {
									onInit: () => this.workShiftService.getAll(),
								},
							},
						],
					},
					{
						props: { label: $localize`Kirjauslajit` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								type: 'event-type-table',
								className: 'g-col-12',
								props: {
									items: this.eventTypeService.eventTypesForWorktimeGroup$,
								},
							},
						],
					},
				],
				hooks: {
					onInit: () => {
						this.workShiftService.getAll()
						this.eventTypeService.getAll()
					},
				},
			},
		]
	}

	override mapEntityToSidebarItem = (worktimeGroup: WorktimeGroup) => {
		return {
			title: worktimeGroup.name,
			id: selectWorktimeGroupId(worktimeGroup).toString(),
			subtitle: worktimeGroup.description,
			field1: 'Id: ' + worktimeGroup.id,
			field2: '',
		}
	}
}

export const worktimeGroupEntityType: EntityType = {
	title: $localize`Työaikaryhmät`,
	path: 'worktime-groups',
	serviceToken: new InjectionToken<WorktimeGroupEntityService>(
		'worktime-groups',
		{
			factory: () => inject(WorktimeGroupEntityService),
		}
	),
	createNewContentComponent: WorktimeGroupCreateNewContentComponent,
}
