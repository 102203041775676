import { FormlyFieldConfig } from '@ngx-formly/core'
import {
	DimensionLevelService,
	DimensionService,
} from 'src/app/core/ngrx-store/entity-services'
import { Dimension } from 'src/app/core/ngrx-store/models'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { EntityForm } from '../../models'
import { selectRouterEntityId } from 'src/app/core/ngrx-store/router/router.selectors'
import { map, withLatestFrom } from 'rxjs'
import { dimensionSelector } from 'src/app/core/ngrx-store/dimension/dimension.selectors'

@Component({
	standalone: true,
	selector: 'kk-dimension-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi kohde"
			i18n-title
		/>
	`,
})
export class DimensionCreateNewContentComponent implements EntityForm {
	constructor(
		private dimensionService: DimensionService,
		private dimensionLevelService: DimensionLevelService
	) {}

	activeEntityId =
		this.dimensionService.store.selectSignal(selectRouterEntityId)()
	model: Partial<Dimension> = {}

	/**
	 * Get child levels of the active dimension, or root levels if none is active
	 */
	dimensionLevelOptions = this.dimensionLevelService.entities$.pipe(
		withLatestFrom(this.dimensionService.entityMap$),
		map(([levels, dimensionMap]) => {
			const activeEntity = dimensionMap[Number(this.activeEntityId)]

			return levels
				.filter((level) =>
					activeEntity
						? level.parentId === activeEntity.dimensionLevelId
						: !level.parentId
				)
				.map((level) => ({
					value: level.id,
					label: level.description,
				}))
		})
	)

	fields: FormlyFieldConfig[] = [
		{
			type: 'input',
			props: {
				label: $localize`Pääryhmä`,
				disabled: true,
				attributes: {
					'data-testid': 'parentDimensionSelect',
				},
			},
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					const dimensionMap = this.dimensionService.store.selectSignal(
						dimensionSelector.selectEntityMap
					)()
					const activeEntity = dimensionMap[Number(this.activeEntityId)]
					field.formControl?.setValue(activeEntity?.name)
				},
			},
			expressions: {
				hide: () => !this.activeEntityId,
			},
		},
		{
			key: 'dimensionLevelId',
			type: 'select',
			props: {
				label: $localize`Kohdetaso`,
				options: this.dimensionLevelOptions,
				required: true,
				attributes: {
					'data-testid': 'dimensionLevelSelect',
				},
			},
			hooks: {
				onInit: () => this.dimensionLevelService.getAll(),
			},
		},
		{
			key: 'name',
			type: 'input',
			props: {
				label: 'Nimi',
				required: true,
			},
		},
		{
			key: 'dimensionId',
			type: 'input',
			className: 'g-col-12',
			props: {
				label: $localize`Valinnainen id`,
			},
		},
	]

	onSubmit() {
		this.dimensionService.add(
			{
				...this.model,
				parentId: this.activeEntityId ? Number(this.activeEntityId) : undefined,
				worktimeGroups: [],
			},
			{ isOptimistic: false }
		)
	}
}
