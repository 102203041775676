export enum OvertimePractice {
	Flex = 'Flex',
	Paid = 'Paid',
	Vacation = 'Vacation',
	Ignore = 'Ignore',
	HourByHour = 'HourByHour',
}

export const overtimePracticeOptions = [
	{
		value: OvertimePractice.Flex,
		label: $localize`Liukumaan`,
		worktimeGroupKey: 'overtimePracticeFlex',
	},
	{
		value: OvertimePractice.Paid,
		label: $localize`Rahana`,
		worktimeGroupKey: 'overtimePracticePaid',
	},
	{
		value: OvertimePractice.Vacation,
		label: $localize`Vapaana`,
		worktimeGroupKey: 'overtimePracticeVacation',
	},
	{
		value: OvertimePractice.Ignore,
		label: $localize`Ei huomioida`,
		worktimeGroupKey: 'overtimePracticeIgnore',
	},
	{
		value: OvertimePractice.HourByHour,
		label: $localize`Tunti tunnista`,
		worktimeGroupKey: 'overtimePracticeHourByHour',
	},
]
