import { HttpUrlGenerator } from '@ngrx/data'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { WorktimeGroup } from './worktime-group.model'
import { PaginationDataService } from '../paginated-data.service'
import { Observable } from 'rxjs'

@Injectable()
export class WorktimeGroupDataService extends PaginationDataService<WorktimeGroup> {
	constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super('WorktimeGroup', http, httpUrlGenerator)
	}

	override getAll(): Observable<WorktimeGroup[]> {
		return this.getAllPages()
	}
}
