import { Component, ChangeDetectionStrategy } from '@angular/core'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { Person } from 'src/app/core/ngrx-store/models'
import { PersonTemplateService } from 'src/app/core/ngrx-store/person/person.service'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { requiredTextField } from 'src/app/shared/components/form/formly-field'
import { EntityForm } from '../../models'
import { ManagementService } from '../../management.service'

@Component({
	standalone: true,
	selector: 'kk-person-create-template',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi mallipohja henkilöltä"
			i18n-title
		/>
	`,
})
export class PersonCreateTemplateComponent implements EntityForm {
	constructor(
		private personTemplateService: PersonTemplateService,
		private managementService: ManagementService
	) {}

	entity = this.managementService.entity
	model: Partial<Person> = {
		name: '',
	}

	fields: FormlyFieldConfig[] = [requiredTextField('name', $localize`Nimi`)]

	onSubmit() {
		this.personTemplateService.add(
			{
				...this.entity(),
				...this.model,
			},
			{ isOptimistic: false }
		)
	}
}
