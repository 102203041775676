import { Event } from 'src/app/core/ngrx-store/models'
import { MbscCalendarEvent } from '@mobiscroll/angular'

export enum RangeType {
	Agenda = 'agenda',
	Day = 'day',
	Week = 'week',
	Month = 'month',
}

export type DateRange = [Date, Date]

export class CalendarEvent implements MbscCalendarEvent, Event {
	id: number
	personNumber: number
	start: string
	end: string
	header: string
	isReadOnly?: boolean
	isSpecialDay?: boolean
	allDay?: boolean
	color?: string
	overlap?: boolean
	realizedWorkshiftId: number
	workshiftContinues: boolean
	eventTypeId: number
	useBackgroundColor: boolean
	stampInReasonId?: number
	stampOutReasonId?: number
	realizedWorkshiftDate?: string

	constructor(init: Partial<CalendarEvent>) {
		Object.assign(this, {
			color: '#7DB43E', // Set default color for events. This will be defined by EventType
			...init,
		})
	}
	get title() {
		return this.header
	}
}

export class AgendaDay {
	events: AgendaEvent[]
	timestamp: number
}

export class AgendaEvent {
	startDate: Date
	endDate: Date
	original?: CalendarEvent
}

export interface SpecialDay {
	id: number
	date: Date
	description: string
	specialDayType: number
}
