export enum SpecialDayType {
	FinnishHoliday = 'FinnishHoliday',
	CompanysShortenedDay = 'CompanysShortenedDay',
	CompanysHoliday = 'CompanysHoliday',
	OtherNonHoliday = 'OtherNonHoliday',
	OtherSpecialDay = 'OtherSpecialDay',
}

export const SpecialDayTypeLabels: { [key in SpecialDayType]: string } = {
	FinnishHoliday: $localize`Suomalaiset juhlapyhät`,
	CompanysShortenedDay: $localize`Yrityskohtaiset lyhennetyt päivät`,
	CompanysHoliday: $localize`Yrityskohtaiset vapaapäivät`,
	OtherNonHoliday: $localize`Muut ei-lomapäivät`,
	OtherSpecialDay: $localize`Muut poikkeuspäivät`,
}
