import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import {
	ModalBodyComponent,
	ModalComponent,
	ModalFooterComponent,
} from 'src/app/shared/components/modal/modal.component'

@Component({
	selector: 'kk-delete-entity-modal',
	standalone: true,
	imports: [ModalComponent, ModalBodyComponent, ModalFooterComponent],
	template: `
		<kk-modal i18n-title title="Oletko varma että haluat poistaa tämän?">
			<kk-modal-body>
				<p i18n>Poiston jälkeen et voi palauttaa tietuetta takaisin</p>
			</kk-modal-body>

			<kk-modal-footer>
				<button
					class="btn btn-secondary"
					(click)="activeModal.dismiss('cancel')"
					i18n
				>
					Peruuta
				</button>

				<button class="btn btn-danger" (click)="onDelete()" i18n>Poista</button>
			</kk-modal-footer>
		</kk-modal>
	`,
})
export class DeleteEntityModalComponent {
	@Input() delete: () => void

	constructor(public activeModal: NgbActiveModal) {}

	onDelete() {
		this.delete()
		this.activeModal.close('Submit')
	}
}
