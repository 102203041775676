export enum EmploymentType {
	MonthlyWages = 'MonthlyWages',
    HourlyWages = 'HourlyWages',
}

export const employmentTypeOptions = [
	{
		value: EmploymentType.MonthlyWages,
		label: $localize`Kuukausipalkka`,
	},
	{
		value: EmploymentType.HourlyWages,
		label: $localize`Tuntipalkka`,
	},
]
