import { Injectable } from '@angular/core'
import {
	EntityActionOptions,
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'

import { WorktimeGroup } from './worktime-group.model'
import { HttpClient } from '@angular/common/http'
import { userFeature } from '../user/user.feature'
import { map, Observable, Subject } from 'rxjs'
import { DateRange } from 'src/app/features/calendar/models'

@Injectable()
export class WorktimeGroupService extends EntityCollectionServiceBase<WorktimeGroup> {
	private _loadedWorktimeGroups: {
		personId: number
		date: Date
		worktimeGroup: WorktimeGroup
	}[] = []

	private currentWorktimeGroup$: Subject<WorktimeGroup> =
		new Subject<WorktimeGroup>()

	constructor(
		private http: HttpClient,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('WorktimeGroup', serviceElementsFactory)
	}

	/**
	 * Override update to clear custom cache
	 */
	override update(
		entity: Partial<WorktimeGroup>,
		options?: EntityActionOptions
	): Observable<WorktimeGroup> {
		// Clear entities from custom cache
		this._loadedWorktimeGroups = this._loadedWorktimeGroups.filter(
			(wtg) => wtg.worktimeGroup.id !== entity.id
		)

		// Call super
		return super.update(entity, options)
	}

	/**
	 * Get current WorktimeGroup
	 * @returns Current WorktimeGroup
	 */
	getCurrentWorktimeGroup(
		personId: number | undefined
	): Observable<WorktimeGroup> {
		return this.getWorktimeGroup(personId, new Date())
	}

	/**
	 * Get WorktimeGroup by date
	 * @param date
	 * @returns WorktimeGroup matching the date
	 */
	getWorktimeGroup(
		personId: number | undefined,
		date: Date
	): Observable<WorktimeGroup> {
		if (!personId) {
			personId = this.store.selectSignal(userFeature.selectUser)()?.id
		}

		// Try to get cached worktimegroup
		const cachedItem = this._loadedWorktimeGroups.find(
			(wtg) => wtg.personId === personId! && wtg.date === date
		)?.worktimeGroup

		// Found from cache
		if (cachedItem) {
			this.entities$
				.pipe(map((entities) => entities.find((i) => i.id === cachedItem.id)))
				.subscribe((entity) => {
					this.currentWorktimeGroup$.next(entity!)
				})
		} else {
			// Not found, call api
			this.http
				.get<WorktimeGroup>(
					`worktime-group-history/` + personId + `/` + date.toDateString()
				)
				.subscribe((worktimeGroup) => {
					// Return value and add it to cache
					this.currentWorktimeGroup$.next(worktimeGroup)
					this._loadedWorktimeGroups.push({
						personId: personId!,
						date: date,
						worktimeGroup: worktimeGroup,
					})
				})
		}

		return this.currentWorktimeGroup$
	}

	getWorktimeGroups(
		dateRange: DateRange
	): Observable<Map<Date, WorktimeGroup>> {
		const user = this.store.selectSignal(userFeature.selectUser)
		return this.http.get<Map<Date, WorktimeGroup>>(
			`worktime-group-history/` +
				user()?.id +
				`/` +
				dateRange[0].toDateString() +
				`/` +
				dateRange[1].toDateString()
		)
	}
}
