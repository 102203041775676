import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
	NgbDropdown,
	NgbDropdownItem,
	NgbDropdownMenu,
	NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap'

export interface DropdownOption {
	title: string
	action: (id?: number) => void
}

@Component({
	selector: 'kk-header-dropdown',
	standalone: true,
	imports: [
		CommonModule,
		NgbDropdown,
		NgbDropdownToggle,
		NgbDropdownMenu,
		NgbDropdownItem,
	],
	styleUrls: ['./header-dropdown.component.scss'],
	template: `
		<div ngbDropdown display="dynamic" class="h-100">
			<button
				class="h-100 w-100 d-flex gap-2 justify-content-center align-items-center border-0 px-3 bg-transparent"
				type="button"
				[id]="id"
				ngbDropdownToggle
				data-testid="dropdown-toggle"
			>
				<ng-content></ng-content>
				<i
					*ngIf="caret"
					class="fa-regular fa-solid fa-caret-down text-secondary"
					data-testid="dropdown-caret"
				></i>
			</button>
			<div
				ngbDropdownMenu
				[class.d-none]="!options"
				[attr.aria-labelledby]="id"
				class="p-0 rounded-0 shadow-sm"
			>
				<div>
					<button
						ngbDropdownItem
						*ngFor="let option of options"
						(click)="option.action()"
						data-testid="dropdown-item"
						class="p-2"
					>
						<p>
							{{ option.title }}
						</p>
					</button>
				</div>
			</div>
		</div>
	`,
})
export class HeaderDropdownComponent {
	@Input()
	id: string

	@Input()
	options: DropdownOption[]

	/** Enable caret icon on the button */
	@Input()
	caret = true
}
