import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Dimension } from './dimension.model'
import { Observable } from 'rxjs'

@Injectable()
export class DimensionService extends EntityCollectionServiceBase<Dimension> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('Dimension', serviceElementsFactory)
	}

	override getAll(): Observable<Dimension[]> {
		throw new Error('Not implemented')
	}
}
