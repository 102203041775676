import {
	Component,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
	input,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { EntityActionMenuOption } from '../../models'
import {
	NgbDropdown,
	NgbDropdownItem,
	NgbDropdownMenu,
	NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap'

/**
 * Use default change detection, so that changes in form are detected
 * Show parameter is used inside the component, because ngIf statement in management-template causes error https://angular.io/errors/NG0100.
 */
@Component({
	standalone: true,
	templateUrl: './form-controls-header.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	imports: [
		CommonModule,
		NgbDropdown,
		NgbDropdownToggle,
		NgbDropdownMenu,
		NgbDropdownItem,
	],
	selector: 'kk-management-form-controls-header',
	styles: [
		`
			@import 'src/app/styles/colors';

			[ngbDropdownToggle].show {
				background: $neutral-20 !important;
			}
		`,
	],
})
export class ManagementFormControlsHeaderComponent {
	@Output() add = new EventEmitter<void>()
	@Output() update = new EventEmitter<void>()
	@Output() delete = new EventEmitter<void>()
	@Output() selectAction = new EventEmitter<number>()
	show = input(true)
	actionsActive = input(true)
	actionMenuOptions = input<EntityActionMenuOption[]>([])
	newActive = input(true)
	deleteActive = input(true)
	updateActive = input(true)
	showDelete = input<boolean>(true)
	showSubmit = input<boolean>(true)

	updateEntity() {
		this.update.emit()
	}

	deleteEntity() {
		this.delete.emit()
	}

	addEntity() {
		this.add.emit()
	}

	onSelectAction(index: number) {
		this.selectAction.emit(index)
	}
}
