<ng-container
	*ngrxLet="{
		authenticated: authenticated$ | async,
		user: currentUser$ | async
	} as vm"
>
	<div class="vh-100">
		@if (!vm.authenticated) {
			<kk-loading-spinner [visible]="true" />
		} @else if (!environment()) {
			<kk-environment-select
				data-testid="auth-env-select"
			></kk-environment-select>
		} @else {
			<kk-layout [kkLoading]="!vm.user" data-testid="layout">
				@if (vm.user) {
					<router-outlet data-testid="auth-router-outlet"></router-outlet>
				}
			</kk-layout>
		}
	</div>
</ng-container>

<kk-toast-list />
