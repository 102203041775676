import { createFeature, createReducer, on } from '@ngrx/store'

import { User } from './user.model'
import { userApiActions } from './user.actions'

export interface CurrentUserState {
	user: User | undefined
}

export const initialState: CurrentUserState = {
	user: undefined,
}

export const userFeature = createFeature({
	name: 'User',
	reducer: createReducer(
		initialState,
		on(userApiActions.userLoadedFailure, () => initialState),
		on(userApiActions.userLoadedSuccess, (state, { user }) => ({
			...state,
			user,
		}))
	),
})
