import { CommonModule } from '@angular/common'
import {
	Component,
	computed,
	signal,
	Input,
	ChangeDetectionStrategy,
	Output,
	EventEmitter,
	effect,
	input,
} from '@angular/core'
import { RouterModule } from '@angular/router'
import {
	EntityListItemComponent,
	EntityListItemSkeletonComponent,
} from './entity-list-item.component'
import { LetDirective, PushPipe } from '@ngrx/component'
import { EntityType, SidebarItem } from '../../models'
import { ManagementBackButtonComponent } from '../back-button/back-button.component'
import { LoadingDirective } from 'src/app/shared/directives/loading/loading.directive'
import { IconDirective } from 'src/app/shared/directives/icon/icon.directive'

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.Default,
	imports: [
		CommonModule,
		LetDirective,
		PushPipe,
		RouterModule,
		EntityListItemComponent,
		EntityListItemSkeletonComponent,
		ManagementBackButtonComponent,
		LoadingDirective,
		IconDirective,
	],
	selector: 'kk-management-entity-list',
	templateUrl: './entity-list.component.html',
})
export class ManagementEntityListComponent {
	constructor() {
		effect(
			() => {
				const page = this.currentPage()
				const pageCount = this.pageCount()
				if (page > pageCount) {
					this.currentPage.set(Math.max(pageCount, 1))
				}
			},
			{
				allowSignalWrites: true,
			}
		)

		effect(
			() => {
				const activeEntityIndex = this.sidebarItems().findIndex(
					(item) => item.id === this.activeEntityId()
				)

				if (activeEntityIndex >= 0) {
					const activeEntityPage = Math.floor(
						activeEntityIndex / this.pageSize + 1
					)
					this.currentPage.set(activeEntityPage)
				}
			},
			{
				allowSignalWrites: true,
			}
		)
	}

	sidebarItems = input.required<SidebarItem[]>()
	activeEntityId = input.required<string>()

	@Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>()
	@Input() entityType: EntityType | null = null
	@Input() loading: boolean

	pageCount = computed(() =>
		Math.ceil(this.sidebarItems().length / this.pageSize)
	)
	pages = computed(() => {
		const pages = []
		for (let i = 1; i <= this.pageCount(); i++) {
			pages.push(i)
		}
		return pages
	})
	currentPage = signal(1)
	pageSize = 10

	selectPage(page: string) {
		const selectedPage = parseInt(page, 10) || 1
		if (selectedPage > this.pageCount() || selectedPage <= 0) return
		this.currentPage.set(selectedPage)
	}

	getPages() {
		return new Array(Math.floor(this.sidebarItems().length / this.pageSize))
	}

	setCurrentPage(page: number) {
		this.currentPage.set(page)
	}

	hasNextPage() {
		return this.currentPage() < this.pageCount()
	}

	hasPreviousPage() {
		return this.currentPage() > 1
	}

	itemsOnPage = computed(() => {
		const start = (this.currentPage() - 1) * this.pageSize
		const end = this.currentPage() * this.pageSize
		return this.sidebarItems().slice(start, end)
	})
}
