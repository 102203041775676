import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data'
import { News } from './news.model'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'

@Injectable()
export class NewsDataService extends DefaultDataService<News> {
	constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super('News', http, httpUrlGenerator)
	}

	override getAll(): Observable<News[]> {
		return super.getAll().pipe(
			map((newsList) =>
				newsList.map((news) => {
					// Publish date is string in the API so it gets mapped to date in here
					return { ...news, publishDate: new Date(news.publishDate) }
				})
			)
		)
	}
}
