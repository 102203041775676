<div
	class="d-flex flex-column h-100 justify-content-start"
	[kkLoading]="loading && sidebarItems().length > 0"
>
	<div class="h-100 overflow-y-auto" data-testid="ManagementSidebarEntityList">
		<div class="input-group ps-3 pe-4 pb-2">
			<span class="input-group-text" [kkIcon]="'magnifying-glass'"></span>
			<input
				class="form-control"
				i18n-placeholder
				placeholder="Hae"
				#search
				data-testid="ManagementSidebarSearch"
				name="search"
				type="search"
				(input)="searchTermChange.emit(search.value)"
			/>
		</div>
		<div
			class="list-group list-group-flush border border-end-0 border-start-0 border-neutral-10"
		>
			<ng-container *ngIf="loading && sidebarItems().length === 0">
				<a class="list-group-item p-0">
					<kk-management-entity-list-item-skeleton />
				</a>
				<a class="list-group-item p-0">
					<kk-management-entity-list-item-skeleton />
				</a>
				<a class="list-group-item p-0">
					<kk-management-entity-list-item-skeleton />
				</a>
			</ng-container>
			<a
				*ngFor="let item of itemsOnPage()"
				[class.active]="item.id === activeEntityId()"
				class="list-group-item list-group-item-action p-0"
				[routerLink]="['.', entityType?.path, item.id]"
				data-testid="ManagementSidebarEntityItem"
			>
				<kk-management-entity-list-item [item]="item" />
			</a>
		</div>
	</div>
	<div
		class="w-100 d-flex pt-3 flex-row align-items-center justify-content-end mb-1 z-3 box-shadow-top"
		*ngIf="sidebarItems().length > 0"
	>
		<div data-testid="ManagementPaginationItemCount">
			{{ currentPage() * pageSize - pageSize + 1 }} -
			{{ currentPage() * pageSize - (10 - itemsOnPage().length) }} /
			{{ sidebarItems().length }}
		</div>
		<div class="ms-3">
			<select
				class="form-select"
				#pageSelect
				data-testid="ManagementPaginationPageSelect"
				id="paginationInput"
				[value]="currentPage()"
				(change)="selectPage(pageSelect.value)"
				style="max-width: 8rem; border: none; text-overflow: ellipsis"
			>
				<option
					*ngFor="let page of pages()"
					[value]="page"
					[selected]="currentPage() === page"
				>
					{{ page }}/{{ pageCount() }}
				</option>
			</select>
		</div>
		<button
			class="btn btn-link ms-1"
			data-testid="ManagementPaginationPrevious"
			style="color: black"
			[disabled]="!hasPreviousPage()"
			(click)="setCurrentPage(currentPage() - 1)"
		>
			<i class="fa-light fa-chevron-left"></i>
		</button>
		<button
			class="btn btn-link"
			data-testid="ManagementPaginationNext"
			style="color: black"
			[disabled]="!hasNextPage()"
			(click)="setCurrentPage(currentPage() + 1)"
		>
			<i class="fa-light fa-chevron-right"></i>
		</button>
	</div>
</div>
