import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NavMenuOptionComponent } from './nav-menu-option/nav-menu-option.component'
import { AttributeGuardDirective } from 'src/app/shared/directives/attribute-guard/attribute-guard.directive'
import { NavMenuOption } from '../navigation-options'
import { UserService } from 'src/app/core/ngrx-store/entity-services'
import { LetDirective } from '@ngrx/component'
@Component({
	selector: 'kk-nav-menu',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CommonModule,
		NavMenuOptionComponent,
		AttributeGuardDirective,
		LetDirective,
	],
	template: `
		<ng-container
			*ngrxLet="{
				personAttributes: personAttributes()
			} as vm"
		>
			<div
				class="navbar bg-dark d-none d-sm-flex flex-column h-100 p-0 shadow-sm"
			>
				<ul class="navbar-nav w-100 d-none d-sm-flex flex-column">
					<ng-container *ngFor="let option of options">
						<li
							class="nav-item"
							[attr.data-testid]="'nav-item-' + option.id"
							*kkAttributeGuard="
								option.allowAccesAttributes ?? [];
								personAttributes: vm.personAttributes
							"
						>
							<kk-nav-menu-option
								[id]="option.id"
								[title]="option.title"
								[link]="option.link"
								[icon]="option.icon"
								[expanded]="expanded"
							>
							</kk-nav-menu-option>
						</li>
					</ng-container>
				</ul>

				<ul class="navbar-nav w-100 d-none d-sm-flex flex-column">
					<li class="nav-item">
						<kk-nav-menu-option
							(click)="toggleExpansion()"
							[title]="expansionToggleTitle"
							[icon]="
								expanded
									? 'fa-regular fa-arrow-left-to-line'
									: 'fa-regular fa-arrow-right-to-line'
							"
							[expanded]="expanded"
							data-testid="expansion-toggle"
						>
						</kk-nav-menu-option>
					</li>
				</ul>
			</div>
		</ng-container>
	`,
})
export class NavMenuComponent {
	@Input()
	options: NavMenuOption[]

	expanded = false

	expansionToggleTitle = $localize`Sulje`

	personAttributes = this.userService.attributes

	constructor(private userService: UserService) {}

	toggleExpansion() {
		this.expanded = !this.expanded
	}
}
