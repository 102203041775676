import { Injectable } from '@angular/core'
import {
	EntityActionOptions,
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { EventType, UsableOnHourDayEvent } from './event-type.model'
import { createSelector } from '@ngrx/store'
import { selectEventTypes } from './event-type.selectors'
import { selectRouterEntityId } from '../router/router.selectors'
import { Observable } from 'rxjs'

const selectEventTypesForWorktimeGroup = createSelector(
	selectEventTypes,
	selectRouterEntityId,
	(eventTypes, entityId) =>
		eventTypes.filter((item) => item.worktimeGroups.includes(Number(entityId)))
)

@Injectable()
export class EventTypeService extends EntityCollectionServiceBase<EventType> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('EventType', serviceElementsFactory)
	}

	/**
	 *
	 * @returns Event types for the activated person group in management view
	 */
	eventTypesForWorktimeGroup$ = this.store.select(selectEventTypesForWorktimeGroup)
	
	override update(entity: Partial<EventType>, options?: EntityActionOptions): Observable<EventType> {
		switch(entity.usableOnHourDayEventSelection) {
			case UsableOnHourDayEvent.UsableOnHourAndDayEvent: {
                entity.usableOnHourEvent = true;
                entity.usableOnDayEvent = true;
                break;
            }
            case UsableOnHourDayEvent.UsableOnHourEvent: {
                entity.usableOnHourEvent = true;
                entity.usableOnDayEvent = false;
                break;
            }
            case UsableOnHourDayEvent.UsableOnDayEvent: {
                entity.usableOnHourEvent = false;
                entity.usableOnDayEvent = true;
                break;
            }
		}

		return super.update(entity, options)
	}
	
}
