export abstract class DateUtils {
	public static getFirstDateOfMonth(date: Date): Date {
		date = new Date(date.getTime())
		date.setHours(0, 0, 0, 0)
		date.setDate(1)
		return date
	}

	public static getLastDateOfMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() + 1)
		date.setDate(date.getDate() - 1)
		return date
	}

	public static getFirstDateOfPreviousMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() - 1)
		date.setDate(1)
		return date
	}

	public static getFirstDateOfNextMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() + 1)
		date.setDate(1)
		return date
	}

	public static toIsoStringDatePartOnly(date: Date): string {
		return date.toISOString().substring(0, 10)
	}

	public static datePartsEqual(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() === d2.getTime()
	}

	public static dateIsGreater(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() > d2.getTime()
	}

	public static dateIsGreaterOrEqual(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() >= d2.getTime()
	}

	public static dateIsLesserOrEqual(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() <= d2.getTime()
	}

	public static getIsoWeekNumber(date: Date): number {
		/*
			https://bito.ai/resources/javascript-get-week-number-javascript-explained/
		*/
		date = new Date(date.getTime())
		date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
		const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
		const weekNo = Math.ceil(
			((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
		)
		return weekNo
	}

	public static getTimeOfDayHHmm(date: Date): string {
		let timeOfDay = date.getHours().toString() + '.'
		if (date.getMinutes() < 10) {
			timeOfDay += '0'
		}
		timeOfDay += date.getMinutes().toString()
		return timeOfDay
	}
}
