import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { PersonAttribute } from './person-attribute.model'
import { FilterPattern } from '../entity-metadata'

@Injectable()
export class PersonAttributeService extends EntityCollectionServiceBase<PersonAttribute> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('PersonAttribute', serviceElementsFactory)
	}

	override setFilter(pattern?: FilterPattern<PersonAttribute>): void {
		super.setFilter(pattern)
	}
}
