import { createFeature, createReducer, on } from '@ngrx/store'
import { Toast } from './toast.model'
import { toastActions } from './toast.actions'

export interface ToastStateModel {
	toasts: Toast[]
}

export const initialState: ToastStateModel = {
	toasts: [],
}

export const toastFeature = createFeature({
	name: 'Toast',
	reducer: createReducer(
		initialState,
		on(toastActions.addToast, (state, { toast }) => ({
			...state,
			toasts: [...state.toasts, toast],
		})),
		on(toastActions.deleteToast, (state, { toast }) => ({
			...state,
			toasts: state.toasts.filter((i) => i !== toast),
		})),
		on(toastActions.deleteToastById, (state, { toastId }) => ({
			...state,
			toasts: state.toasts.filter((i) => i.toastId !== toastId),
		}))
	),
})
