import { Injectable, InjectionToken, inject } from '@angular/core'
import {
	EventTypeService,
	StampingReasonService,
	WorktimeGroupService,
} from 'src/app/core/ngrx-store/entity-services'
import { StampingReason } from 'src/app/core/ngrx-store/models'
import {
	EntityService,
	EntityType,
	SidebarItem,
	entitySelectorFactory,
} from '../../models'
import { stampingReasonSelector } from 'src/app/core/ngrx-store/stamping-reason/stamping-reason.selectors'
import { StampingReasonCreateNewComponent } from './stamping-reason-create-new.component'
import { map } from 'rxjs'
import {
	checkboxField,
	emptyRow,
	numberField,
	textField,
} from 'src/app/shared/components/form/formly-field'
import {
	StampDirectionEnum,
	stampDirectionEnumLabels,
	stampTypeLabels,
} from 'src/app/core/ngrx-store/stamping-reason/stamping-reason.model'

export const selectStampingReasonEntity = entitySelectorFactory(
	stampingReasonSelector
)

@Injectable({ providedIn: 'root' })
export class StampingReasonEntityService extends EntityService<StampingReason> {
	formatDescriptionsToTitle(inDescription?: string, outDescription?: string) {
		if (inDescription && outDescription) {
			return `${inDescription} - ${outDescription}`
		} else if (inDescription) {
			return inDescription
		} else if (outDescription) {
			return outDescription
		} else {
			return ''
		}
	}

	override mapEntityToSidebarItem = (
		stampingReason: StampingReason
	): SidebarItem => {
		return {
			id: stampingReason.id.toString(),
			title: this.formatDescriptionsToTitle(stampingReason.name),
		}
	}

	constructor(
		private stampingReasonService: StampingReasonService,
		private worktimeGroupService: WorktimeGroupService,
		private eventTypeService: EventTypeService
	) {
		super(stampingReasonService, selectStampingReasonEntity)

		this.formlyFields = [
			{
				type: 'tabs',
				fieldGroup: [
					{
						props: { label: $localize`Perustiedot` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							textField('name', $localize`Nimi`, 'g-col-12'),
							{
								key: 'stampDirection',
								type: 'select',
								className: 'g-col-6',
								props: {
									label: $localize`Leimaussuunta`,
									options: Object.entries(stampDirectionEnumLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
								},
							},
							{
								key: 'stampType',
								type: 'select',
								className: 'g-col-6',
								props: {
									label: $localize`Leimaustyyppi`,
									options: Object.entries(stampTypeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
								},
							},
							{
								key: 'worktimeGroups',
								type: 'multiselect',
								className: 'g-col-12',
								props: {
									label: $localize`Työaikaryhmät`,
									multiple: true,
									items: this.worktimeGroupService.entities$.pipe(
										map((worktimeGroups) =>
											worktimeGroups.map((group) => ({
												value: group.id,
												label: group.name,
											}))
										)
									),
								},
								hooks: {
									onInit: () => this.worktimeGroupService.getAll(),
								},
							},
							numberField('orderNumber', $localize`Näyttöjärjestys`),
							emptyRow,
							checkboxField('isEnabled', $localize`Käytössä`),
							checkboxField('isPublic', $localize`Julkinen tieto`),
						],
					},
					{
						props: { label: $localize`Automaattitäytöt` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								key: 'stampAutomaticFillSettings',
								type: 'stamp-automatic-fill-setting-list',
								className: 'g-col-12',
								props: {
									label: $localize`Automaattitäytöt`,
									eventTypes: this.eventTypeService.entities$,
									stampInReasons: this.stampingReasonService.entities$.pipe(
										map((reasons) =>
											reasons.filter(
												(reason) =>
													reason.stampDirection === StampDirectionEnum.In
											)
										)
									),
									stampOutReasons: this.stampingReasonService.entities$.pipe(
										map((reasons) =>
											reasons.filter(
												(reason) =>
													reason.stampDirection === StampDirectionEnum.Out
											)
										)
									),
								},
							},
						],
					},
				],
				hooks: {
					onInit: () => {
						this.worktimeGroupService.getAll()
						this.eventTypeService.getAll()
					},
				},
			},
		]
	}
}

export const stampingReasonEntityType: EntityType = {
	title: $localize`Leimaussyyt`,
	path: 'stamping-reasons',
	serviceToken: new InjectionToken<StampingReasonEntityService>(
		'stamping-reasons',
		{
			factory: () => inject(StampingReasonEntityService),
		}
	),
	createNewContentComponent: StampingReasonCreateNewComponent,
	featureGate: ['management-stamping-reason'],
}
