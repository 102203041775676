import {
	inject,
	InjectionToken,
	Injectable,
	effect,
	computed,
	untracked,
} from '@angular/core'
import { EntityService, EntityType } from '../../models'
import {
	personSelector,
	selectPersonId,
} from 'src/app/core/ngrx-store/person/person.selectors'
import { Person } from 'src/app/core/ngrx-store/models'
import { PersonService } from 'src/app/core/ngrx-store/person/person.service'
import {
	PersonAttributeService,
	WorktimeGroupService,
} from 'src/app/core/ngrx-store/entity-services'
import { personAttributeSelector } from 'src/app/core/ngrx-store/person-attribute/person-attribute.selectors'
import {
	defaultIfEmpty,
	finalize,
	first,
	forkJoin,
	map,
	of,
	Subject,
	switchMap,
} from 'rxjs'
import { EntityActionOptions } from '@ngrx/data'
import {
	selectRouterEntityId,
	selectRouterEntityTypePath,
} from 'src/app/core/ngrx-store/router/router.selectors'
import { PersonAttributeGroup } from 'src/app/core/ngrx-store/person-attribute/person-attribute.model'
import {
	checkboxField,
	textField,
} from 'src/app/shared/components/form/formly-field'
import { selectPersonManagementEntity } from './person.selectors'
import { PersonCreateNewContentComponent } from './person-create-new-content.component'
import { PersonCreateTemplateComponent } from './person-create-template.component'
import {
	EmploymentType,
	employmentTypeOptions,
} from 'src/app/core/ngrx-store/person/employment-type.model'
import { CompanyLocationsService } from 'src/app/core/ngrx-store/company-locations/company-locations.service'
import { SupervisorService } from 'src/app/core/ngrx-store/supervisor/supervisor.service'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { PersonAttributeId } from 'src/app/core/utils/person-attribute-rules'
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm.component'

/**
 * Interface for combining person-related data. Required for Formly.
 */
export interface PersonManagement extends Person {
	roles?: number[]
	recordingMethods?: number[]
	permissions?: number[]
}

@Injectable({ providedIn: 'root' })
export class PersonEntityService extends EntityService<PersonManagement> {
	entityId =
		this.entityCollectionService.store.selectSignal(selectRouterEntityId)
	entityTypePath = this.entityCollectionService.store.selectSignal(
		selectRouterEntityTypePath
	)
	personAttributes$ = this.personAttributeService.filteredEntities$
	personLoading = this.entityCollectionService.store.selectSignal(
		personSelector.selectLoading
	)
	attributesLoading = this.entityCollectionService.store.selectSignal(
		personAttributeSelector.selectLoading
	)

	worktimeGroups$ = this.worktimeGroupService
	persons$ = this.personservice

	override mapEntityToSidebarItem = (person: PersonManagement) => {
		this.personAttributeService.setFilter({
			type: 'exact',
			value: {
				personId: person.id,
			},
		})
		this.personAttributeService.getWithQuery({
			personId: person.id,
		})

		return {
			id: selectPersonId(person)?.toString(),
			title: this.personAttributeService.entities$.pipe(
				map(
					(attributes) =>
						attributes
							.filter(
								(attr) =>
									attr.personId === person.id &&
									attr.isChecked &&
									attr.groupId === PersonAttributeGroup.Role
							)
							.sort((a1, a2) => (a1.customOrder < a2.customOrder ? 1 : -1))[0]
				),
				map((attr) =>
					attr !== undefined
						? person.name + ' (' + attr.displayDescription + ')'
						: person.name
				)
			),
			subtitle: of(person.email),
			fields$: [
				this.worktimeGroupService.entities$.pipe(
					map((i) => i.find((j) => j.id === person.worktimeGroupId)),
					map((i) =>
						i?.name
							? $localize`Työaikaryhmä` + ': ' + i?.name
							: $localize`Ei valittua työaikaryhmää`
					)
				),
				this.personservice.entities$.pipe(
					map((prs) => prs.find((p) => p.id === person.supervisor)),
					map((p) => (p ? $localize`Esihenkilö` + ': ' + p.name : ''))
				),
			],
		}
	}

	constructor(
		personService: PersonService,
		private personAttributeService: PersonAttributeService,
		private worktimeGroupService: WorktimeGroupService,
		private companyLocationsService: CompanyLocationsService,
		private supervisorService: SupervisorService,
		private personservice: PersonService,
		private modalService: NgbModal
	) {
		super(personService, selectPersonManagementEntity)

		/**
		 * Load personAttributes for the selected person
		 */
		effect(
			() => {
				const entityId = this.entityId()
				const entityTypePath = this.entityTypePath()

				if (!entityId || entityTypePath !== personEntityType.path) {
					return
				}

				const person = untracked(() => this.entity())

				if (!person) {
					return
				}

				this.personAttributeService.setFilter({
					type: 'exact',
					value: { personId: person.id },
				})
				this.personAttributeService.getWithQuery({
					personId: person.id,
				})
			},
			{ allowSignalWrites: true }
		)

		this.loading = computed(() => {
			if (this.personLoading() || this.attributesLoading()) return true
			return false
		})

		this.formlyFields = [
			{
				type: 'tabs',
				fieldGroup: [
					{
						props: { label: $localize`Henkilötiedot` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							checkboxField('lemonuserBit', $localize`Aktiivinen käyttäjä`),
							textField('name', $localize`Nimi`),
							textField('address1', $localize`Osoite`),
							textField('postalCode', $localize`Postinumero`),
							textField('city', $localize`Kaupunki`),
							textField('country', $localize`Maa`),
							textField('email', $localize`Sähköposti`),
							textField('gsm', $localize`Matkapuhelin`),
							textField('languageCode', $localize`Kieli`),

							{
								key: 'employmentType',
								type: 'select',
								className: 'g-col-6',
								props: {
									label: $localize`Työsuhteen tyyppi`,
									options: employmentTypeOptions.map(({ value, label }) => ({
										value: value,
										label,
									})),
									defaultValue: EmploymentType.MonthlyWages,
								},
							},
							{
								key: 'supervisor',
								type: 'select',
								className: 'g-col-6',
								props: {
									required: false,
									label: $localize`Esihenkilö`,
								},
								expressions: {
									'props.options': (field: FormlyFieldConfig) => {
										return this.supervisorService.entities$.pipe(
											map((supervisors) =>
												supervisors
													.filter(
														(supervisor) => supervisor.id !== field.model.id
													)
													.map((supervisor) => ({
														value: supervisor.id,
														label: supervisor.id === 0 ? '' : supervisor.name,
													}))
											)
										)
									},
								},
								hooks: {
									onInit: () => this.supervisorService.clearCache(),
								},
							},
							{
								key: 'worktimeGroupId',
								type: 'select',
								className: 'g-col-6 remove-select-background',
								props: {
									readonly: true,
									required: false,
									disabled: true,
									label: $localize`Työaikaryhmä`,
									options: this.worktimeGroupService.entities$.pipe(
										map((worktimeGroups) =>
											worktimeGroups.map((worktimeGroup) => ({
												value: worktimeGroup.id,
												label: worktimeGroup.name,
											}))
										)
									),
								},
							},
							{
								type: 'worktimeGroupChangeButton',
								key: 'id',
								className: 'g-col-6',
								props: {
									label: $localize`Vaihda ryhmää`,
								},
							},
							{
								key: 'office',
								type: 'select',
								className: 'g-col-12',
								props: {
									required: false,
									label: $localize`Toimipaikka`,
									options: this.companyLocationsService.entities$.pipe(
										map((companyLocations) =>
											companyLocations.map((location) => ({
												value: location.locationId,
												label: location.locationName,
											}))
										)
									),
								},
							},
							{
								key: 'timeCounterStartDate',
								type: 'datetime',
								className: 'g-col-12',
								props: {
									label: $localize`Saldolaskennan aloituspäivä`,
									controls: ['date'],
									dateFormat: 'D.M.YYYY',
									returnFormat: 'iso8601',
								},
							},
						],
						hooks: {
							onInit: () => {
								this.worktimeGroupService.getAll()
								this.companyLocationsService.getAll()
								this.supervisorService.getAll()
							},
						},
					},
					{
						props: { label: $localize`Asetukset` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								key: 'roles',
								type: 'multicheckbox',
								className: 'g-col-12',
								props: {
									label: $localize`Roolit`,
									type: 'array',
									options: this.personAttributes$.pipe(
										map((personAttributes) =>
											personAttributes
												.filter(
													(attribute) =>
														attribute.groupId === PersonAttributeGroup.Role
												)
												.sort((a1, a2) =>
													a1.customOrder > a2.customOrder ? 1 : -1
												)
												.map((attribute) => ({
													value: attribute.id,
													label: attribute.displayDescription,
												}))
										)
									),
									attributes: {
										size: 20,
									},
								},
							},
							{
								key: 'recordingMethods',
								type: 'multicheckbox',
								className: 'g-col-12',
								props: {
									label: $localize`Kirjaustavat`,
									type: 'array',
									options: this.personAttributes$.pipe(
										map((personAttributes) =>
											personAttributes
												.filter(
													(attribute) =>
														attribute.groupId ===
														PersonAttributeGroup.RecordingMethod
												)
												.map((attribute) => ({
													value: attribute.id,
													label: attribute.description,
												}))
										)
									),
									attributes: {
										size: 20,
									},
								},
							},
							{
								key: 'permissions',
								type: 'multicheckbox',
								className: 'g-col-12',
								props: {
									label: $localize`Oikeudet`,
									type: 'array',
									options: this.personAttributes$.pipe(
										map((personAttributes) =>
											personAttributes
												.filter(
													(attribute) =>
														attribute.groupId ===
														PersonAttributeGroup.Permission
												)
												.map((attribute) => ({
													value: attribute.id,
													label: attribute.description,
												}))
										)
									),
									attributes: {
										size: 20,
									},
								},
							},
						],
					},
				],
			},
		]
	}

	override update(
		entity: Partial<PersonManagement>,
		options?: EntityActionOptions
	) {
		const { roles } = entity
		const person$ = new Subject<Person>()

		const selectedAttributes = [...(roles ?? [])]

		// Check if persons "Supervisor"-role has been removed (changed attribute=supervisor and it is not amongst selected/checked)
		this.personAttributeService.filteredEntities$
			.pipe(
				first(),
				map((personAttributes) => {
					return personAttributes.filter(
						(attr) =>
							attr.id === PersonAttributeId.Supervisor &&
							!selectedAttributes.includes(attr.id)
					)[0]
				})
			)
			.subscribe((supervisorCheckRemoved) => {
				// If supervisor-role has been removed, check whether person has any subordinates.
				if (supervisorCheckRemoved) {
					this.supervisorService
						.hasSubordinates(entity.id)
						.subscribe((hasSubordinates) => {
							// If there are atleast one subordinate, ask user if they are aware that all subordinates will be orphaned.
							if (hasSubordinates) {
								const modalRef = this.modalService.open(ConfirmDialogComponent)
								const component =
									modalRef.componentInstance as ConfirmDialogComponent

								component.TitleText = $localize`Alaiset`
								component.ContentText = $localize`Henkilöllä on alaisia. Jos tallennat henkilön, alaisten esihenkilötiedot tyhjennetään.`

								// User agreed to orphan all subordinates.
								component.onConfirm = () => {
									// Save changes to database (this also orphans all subordinates)
									this.doUpdate(entity, options).subscribe((person) => {
										person$.next(person)
									})

									// Orphan all subordinates in cache too. No need to reload all persons from database.
									this.personservice.removeSupervisorFromPersonsCache(entity.id)
								}
							} else {
								this.doUpdate(entity, options).subscribe((person) =>
									person$.next(person)
								)
							}
						})
				} else {
					this.doUpdate(entity, options).subscribe((person) =>
						person$.next(person)
					)
				}
			})

		return person$
	}

	/**
	 * Save changes to database
	 */
	private doUpdate(
		entity: Partial<PersonManagement>,
		options?: EntityActionOptions
	) {
		const { roles, recordingMethods: features, permissions, ...person } = entity
		const selectedAttributes = [
			...(roles ?? []),
			...(features ?? []),
			...(permissions ?? []),
		]

		this.entityCollectionService.setLoading(true)

		// Update changed attributes individually, then update person
		return this.personAttributeService.filteredEntities$.pipe(
			first(),
			switchMap((personAttributes) => {
				const attributeUpdates = personAttributes
					// Filter to include only changed attributes
					.filter((attr) =>
						attr.isChecked
							? !selectedAttributes.includes(attr.id)
							: selectedAttributes.includes(attr.id)
					)
					.map((attr) => ({
						...attr,
						isChecked: selectedAttributes.includes(attr.id),
					}))
					.map((attr) => this.personAttributeService.update(attr, options))

				return forkJoin(attributeUpdates)
			}),
			defaultIfEmpty([]),
			switchMap(() => this.entityCollectionService.update(person, options)),
			finalize(() => this.entityCollectionService.setLoading(false))
		)
	}
}

export const personEntityType: EntityType = {
	title: $localize`Henkilötiedot`,
	path: 'persons',
	serviceToken: new InjectionToken<PersonEntityService>('persons', {
		factory: () => inject(PersonEntityService),
	}),
	createNewContentComponent: PersonCreateNewContentComponent,
	actionMenuOptions: [
		{
			title: $localize`Kopioi mallipohjaksi`,
			component: PersonCreateTemplateComponent,
		},
	],
}
