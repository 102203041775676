import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { DimensionLevel } from './dimension-level.model'

@Injectable()
export class DimensionLevelService extends EntityCollectionServiceBase<DimensionLevel> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('DimensionLevel', serviceElementsFactory)
	}
}
