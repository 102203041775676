import { createSelector } from '@ngrx/store'
import { personAttributeSelector } from 'src/app/core/ngrx-store/person-attribute/person-attribute.selectors'
import { entitySelectorFactory } from '../../models'
import { personSelector } from 'src/app/core/ngrx-store/person/person.selectors'
import { PersonManagement } from './person'
import { PersonAttributeGroup } from 'src/app/core/ngrx-store/person-attribute/person-attribute.model'

export const selectPersonEntity = entitySelectorFactory(personSelector)

export const selectPersonManagementEntity = createSelector(
	selectPersonEntity,
	personAttributeSelector.selectEntities,

	(person, attributes) => {
		if (person) {
			attributes = attributes.filter(
				(attr) => attr.personId === person.id && attr.isChecked
			)
			const data: PersonManagement = {
				...person,
				// Person's attributes are divided into 3 separate categories based on their group
				roles: attributes
					.filter((attr) => attr.groupId === PersonAttributeGroup.Role)
					.map((attr) => attr.id),
				recordingMethods: attributes
					.filter(
						(attr) => attr.groupId === PersonAttributeGroup.RecordingMethod
					)
					.map((attr) => attr.id),
				permissions: attributes
					.filter((attr) => attr.groupId === PersonAttributeGroup.Permission)
					.map((attr) => attr.id),
			}
			return data
		}
		return null
	}
)
