/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
} from '@angular/router'
import { ToastService, UserService } from '../../ngrx-store/entity-services'
import { inject } from '@angular/core'
import { PersonAttribute } from '../../ngrx-store/models'
import { filter, firstValueFrom, map, tap } from 'rxjs'

/**
 * Guard for protecting routes that require specific attributes.
 * Provide attributeGuardAllowAccess parameter for route (array of attribute ids which gives
 * access to the route)
 */
export const attributeGuard: CanActivateFn = async (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Promise<boolean> => {
	const toastService = inject(ToastService)
	const service = inject(UserService)
	const router = inject(Router)
	const attributesLoading = service.attributesLoading
	// Check if user and attributes has been loaded
	if (service.attributes() === null) {
		// Get user and attributes if the user navigates directly to the app with a link.
		service.getCurrentUser()
		// set attributes loading, so the filter does not pass after user is fetched
		service.setLoading()
	}

	const attributeGuardAllowAccess = route.data['attributeGuardAllowAccess'].map(
		(attr: string) => Number(attr)
	)
	const allowed = await firstValueFrom(
		service.attributes$.pipe(
			filter((attr) => attr !== null && !attributesLoading()),
			map((attr) => hasAccess(attr!, attributeGuardAllowAccess)),
			tap((hasAccess) => {
				if (!hasAccess) {
					toastService.showError(
						$localize`Käyttäjälläsi ei ole oikeuksia kyseiselle sivulle.`
					)
					router.navigateByUrl('')
				}
			})
		)
	)
	return allowed
}

/**
 * Helper function for checking if users attributes contain one of the attributes
 * that allow viewing the component / route.
 * @param {PersonAttribute[]} attributes Array of active attributes of the current user
 * @param {number[]} allowAccessAttributes Array of attribute Ids that allow access
 * @returns {Boolean} True if user has attribute that allows access, False else.
 *    Returns true if allowAccessAttributes is empty array (meaning that it is visible to everyone)
 */
export function hasAccess(
	attributes: PersonAttribute[],
	allowAccessAttributes: number[]
) {
	let allowAccess = false
	const attributeIds = attributes.map((a) => a.id)
	if (allowAccessAttributes.length === 0) {
		return true
	}
	for (const attribute of allowAccessAttributes) {
		if (attributeIds.includes(attribute)) {
			allowAccess = true
			break
		}
	}
	return allowAccess
}
