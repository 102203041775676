export interface Toast {
	/**
	 * A unique id of a toast
	 */
	toastId?: string | number

	/**
	 * Toast heading
	 */
	heading: string
	/**
	 * Toast message
	 */
	message: string

	/**
	 * Type of toast success, error etc.
	 */
	variant: ToastVariant

	/**
	 * Show no button
	 */
	showNoButton?: boolean

	/**
	 *
	 * no button event handler
	 */
	noClick?: () => void

	/**
	 * Show yes button
	 */
	showYesButton?: boolean

	/**
	 * Yes button click event handler
	 */
	yesClick?: () => void

	/**
	 * Enable auto close
	 */
	autoClose?: boolean
	/**
	 * Automatically delete toast after this delay in ms. Default 10000 ms
	 */
	autoCloseDelayMs?: number
}

export enum ToastVariant {
	Success = 'Success',
	Error = 'Error',
	Default = 'Default',
	Info = 'Info',
	Alert = 'Alert',
}
