import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Holiday } from './holiday.model'

@Injectable()
export class HolidaysService extends EntityCollectionServiceBase<Holiday> {
	private _loadedYears = new Set<number>()

	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('Holidays', serviceElementsFactory)
	}

	loadByYear(year: number) {
		if (this._loadedYears.has(year)) return

		this._loadedYears.add(year)

		this.getWithQuery({ year }).subscribe({
			error: () => this._loadedYears.delete(year),
		})
	}
}
