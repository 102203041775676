import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { WidgetInfo } from './widget.model'

@Injectable()
export class AvailableCardWidgetService extends EntityCollectionServiceBase<WidgetInfo> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('AvailableCardWidget', serviceElementsFactory)
	}
}
