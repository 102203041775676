import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Toast, ToastVariant } from './toast.model'
import { toastActions } from './toast.actions'
import { selectToasts } from './toast.selector'

@Injectable({ providedIn: 'root' })
export class ToastService {
	constructor(private store: Store) {}

	public toasts$ = this.store.select(selectToasts)

	public showToast(toast: Toast) {
		this.store.dispatch(
			toastActions.addToast({
				toast: toast,
			})
		)
	}

	public showError(message: string, heading?: string) {
		this.showToast({
			heading: heading ?? $localize`Virhe`,
			message: message,
			variant: ToastVariant.Error,
			autoClose: true,
		})
	}

	public showSuccess(message: string, heading?: string) {
		this.showToast({
			heading: heading ?? $localize`Onnistui`,
			message: message,
			variant: ToastVariant.Success,
			autoClose: true,
		})
	}

	public deleteToast(toast: Toast) {
		this.store.dispatch(toastActions.deleteToast({ toast: toast }))
	}

	public deleteToastById(toastId: string | number) {
		this.store.dispatch(toastActions.deleteToastById({ toastId }))
	}
}
