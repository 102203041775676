import {
	HttpHandlerFn,
	HttpInterceptorFn,
	HttpRequest,
} from '@angular/common/http'
import { inject } from '@angular/core'
import { APP_CONFIG_TOKEN } from '../../utils/app-config'

/**
 * Adds api path to the request from `AppConfig`
 */
export const baseUrlInterceptor: HttpInterceptorFn = (
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
) => {
	const appConfig = inject(APP_CONFIG_TOKEN)
	const url = `${appConfig.apiUrl ?? ''}/api/${req.url}`
	return next(req.clone({ url: url }))
}
