import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { WidgetInfo } from './widget.model'

@Injectable()
export class AvailableListWidgetService extends EntityCollectionServiceBase<WidgetInfo> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('AvailableListWidget', serviceElementsFactory)
	}
}
