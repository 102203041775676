import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router'
import { inject } from '@angular/core'
import { ENTITY_TYPE_MAP_TOKEN } from 'src/app/features/management/entity-type.provider'

/**
 * Uses already filtered `entityTypeMap` to check if the requested `EntityType` management feature is enabled.
 * @see `entityTypeProviderFactory` for details about filtering.
 * @param route ActivatedRouteSnapshot that contains required features in a `featureGate?: Feature[]`
 * @returns `true` if all the required features are enabled
 */
export const managementFeatureGuard: CanActivateFn = async (
	route: ActivatedRouteSnapshot
) => {
	const entityTypeMap = inject(ENTITY_TYPE_MAP_TOKEN)
	const router = inject(Router)
	const entityTypePath = route.paramMap.get('entityTypePath')

	// Entity type is found in the map, so it has passed the feature flags
	if (entityTypePath && entityTypeMap[entityTypePath]) return true

	await router.navigate(['/'])
	return false
}
