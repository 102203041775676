import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'

import { WorktimeGroup } from './worktime-group.model'
import { HttpClient } from '@angular/common/http'
import { userFeature } from '../user/user.feature'
import { Observable } from 'rxjs'
import { DateRange } from 'src/app/features/calendar/models'

@Injectable()
export class WorktimeGroupService extends EntityCollectionServiceBase<WorktimeGroup> {
	constructor(
		private http: HttpClient,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('WorktimeGroup', serviceElementsFactory)
	}

	getCurrentWorktimeGroup(): Observable<WorktimeGroup> {
		const user = this.store.selectSignal(userFeature.selectUser)
		const date = new Date()
		return this.http.get<WorktimeGroup>(
			`worktime-group-history/` + user()?.number + `/` + date.toDateString()
		)
	}

	getWorktimeGroup(date: Date): Observable<WorktimeGroup> {
		const user = this.store.selectSignal(userFeature.selectUser)
		return this.http.get<WorktimeGroup>(
			`worktime-group-history/` + user()?.number + `/` + date.toDateString()
		)
	}

	getWorktimeGroups(
		dateRange: DateRange
	): Observable<Map<Date, WorktimeGroup>> {
		const user = this.store.selectSignal(userFeature.selectUser)
		return this.http.get<Map<Date, WorktimeGroup>>(
			`worktime-group-history/` +
				user()?.number +
				`/` +
				dateRange[0].toDateString() +
				`/` +
				dateRange[1].toDateString()
		)
	}
}
