import { Component, ChangeDetectionStrategy } from '@angular/core'
import { FormlyFieldConfig } from '@ngx-formly/core'
import {
	PersonService,
	PersonTemplateService,
} from 'src/app/core/ngrx-store/person/person.service'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { textField } from 'src/app/shared/components/form/formly-field'
import { EntityForm } from '../../models'
import { map } from 'rxjs'
import { HttpOptions } from '@ngrx/data/src/dataservices/interfaces'

@Component({
	standalone: true,
	selector: 'kk-person-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi henkilö"
			i18n-title
		/>
	`,
})
export class PersonCreateNewContentComponent implements EntityForm {
	constructor(
		private personService: PersonService,
		private personTemplateService: PersonTemplateService
	) {
		this.personTemplateService.getAll()
	}

	model: { name: string; templateNumber?: number } = {
		name: '',
		templateNumber: undefined,
	}

	fields: FormlyFieldConfig[] = [
		textField('name', $localize`Nimi`),
		{
			key: 'templateNumber',
			type: 'select',
			props: {
				label: $localize`Mallipohja`,
				options: this.personTemplateService.entities$.pipe(
					map((templates) => [
						{
							value: undefined,
							label: '-',
						},
						...templates.map((template) => ({
							value: template.number,
							label: template.name,
						})),
					])
				),
			},
		},
	]

	onSubmit() {
		const httpOptions: HttpOptions = {}

		if (this.model.templateNumber) {
			httpOptions.httpParams = {
				fromObject: {
					templateNumber: this.model.templateNumber,
				},
			}
		}

		this.personService.add(
			{ name: this.model.name },
			{ isOptimistic: false, httpOptions }
		)
	}
}
