import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
	standalone: true,
	selector: 'kk-view-template',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./view-template.component.scss'],
	imports: [CommonModule],
	template: `
		<div class="view-template-container">
			<div
				class="view-template-header d-lg-block d-none bg-white drop-shadow-y"
				data-testid="viewTemplateHeader"
			>
				<ng-content select="[header]"></ng-content>
			</div>

			<div
				class="d-lg-none d-flex flex-row align-items-center view-template-header-mobile bg-prussian-100 py-2 px-3 text-white"
				data-testid="viewTemplateHeaderMobile"
			>
				<ng-content select="[headerMobile]"></ng-content>
			</div>

			<div
				*ngIf="showContentHeader"
				class="view-template-content-header d-none d-lg-block bg-white p-4"
				data-testid="viewTemplateContentHeader"
			>
				<ng-content select="[contentHeader]"></ng-content>
			</div>

			<div class="view-template-content-container d-flex">
				<div
					class="view-template-sidebar bg-white flex-grow-1 flex-lg-grow-0"
					[ngClass]="{
						'd-flex': showSidebarMobile,
						'd-lg-flex': showSidebarDesktop,
						'd-none': !showSidebarMobile,
						'd-lg-none': !showSidebarDesktop
					}"
					data-testid="viewTemplateSidebar"
				>
					<ng-content select="[sidebar]"></ng-content>
				</div>

				<div
					class="view-template-content d-lg-flex flex-grow-1"
					[ngClass]="{
						'd-flex': showContentMobile,
						'd-none': !showContentMobile
					}"
					data-testid="viewTemplateContent"
				>
					<ng-content select="[content]"></ng-content>
				</div>
			</div>
			<div
				*ngIf="showFooter"
				class="view-template-footer d-lg-none bg-prussian-90 py-2 px-3"
				data-testid="viewTemplateFooter"
			>
				<ng-content select="[footer]"></ng-content>
			</div>
		</div>
	`,
})
/**
 * Template for rendering header sidebar and content.
 * By default sidebar and content are both shown in desktop view and In smaller devices
 * the sidebar is hidden.
 * Footer is only visible on mobile devices and hidden if showFooter is not set to true
 */
export class ViewTemplateComponent {
	@Input() showContentHeader = false
	@Input() showSidebarMobile = false
	@Input() showSidebarDesktop = true
	@Input() showContentMobile = true
	@Input() showFooter = false
}
