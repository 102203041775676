export abstract class DateUtils {
	public static getFirstDateOfMonth(date: Date): Date {
		date = new Date(date.getTime())
		date.setHours(0, 0, 0, 0)
		date.setDate(1)
		return date
	}

	public static getLastDateOfMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() + 1)
		date.setDate(date.getDate() - 1)
		return date
	}

	public static getFirstDateOfPreviousMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() - 1)
		date.setDate(1)
		return date
	}

	public static getFirstDateOfNextMonth(date: Date): Date {
		date = DateUtils.getFirstDateOfMonth(date)
		date.setMonth(date.getMonth() + 1)
		date.setDate(1)
		return date
	}

	public static toIsoStringDatePartOnly(date: Date): string {
		return date.toISOString().substring(0, 10)
	}

	public static datePartsEqual(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() === d2.getTime()
	}

	public static dateIsGreater(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() > d2.getTime()
	}

	public static dateIsGreaterOrEqual(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() >= d2.getTime()
	}

	public static dateIsLesserOrEqual(
		date1: string | Date,
		date2: string | Date
	): boolean {
		const d1 = new Date(date1)
		d1.setHours(0, 0, 0, 0)
		const d2 = new Date(date2)
		d2.setHours(0, 0, 0, 0)
		return d1.getTime() <= d2.getTime()
	}

	public static getIsoWeekNumber(date: Date): number {
		/*
			https://bito.ai/resources/javascript-get-week-number-javascript-explained/
		*/
		date = new Date(date.getTime())
		date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
		const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
		const weekNo = Math.ceil(
			((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
		)
		return weekNo
	}

	public static getTimeOfDayHHmm(date: Date): string {
		let timeOfDay = date.getHours().toString() + '.'
		if (date.getMinutes() < 10) {
			timeOfDay += '0'
		}
		timeOfDay += date.getMinutes().toString()
		return timeOfDay
	}

	/**
	 * Returns the difference between two date strings in the specified unit.
	 * @param start1 - The start date string.
	 * @param start2 - The end date string.
	 * @param unit - The unit of time to return the difference in ('hours', 'minutes', 'seconds', or 'milliseconds').
	 * @returns The difference between the two dates in the specified unit.
	 */
	public static calculateTimeDifference(
		start1: string,
		start2: string,
		unit: 'hours' | 'minutes' | 'seconds' | 'milliseconds' = 'milliseconds'
	): number {
		const differenceInMilliSeconds = Math.abs(
			new Date(start1).getTime() - new Date(start2).getTime()
		)

		switch (unit) {
			case 'hours':
				return differenceInMilliSeconds / (60 * 60 * 1000)
			case 'minutes':
				return differenceInMilliSeconds / (60 * 1000)
			case 'seconds':
				return differenceInMilliSeconds / 1000
			default:
				return differenceInMilliSeconds
		}
	}

	/*
	 * Returns the date in the format 'YYYY-MM-DDTHH:mm:ss' without timezone information.
	 * @param date - The date to format.
	 * @returns The formatted date string.
	 */
	public static formatDateWithoutTimezone(date: Date): string {
		const year = date.getFullYear()
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const day = String(date.getDate()).padStart(2, '0')
		const hours = String(date.getHours()).padStart(2, '0')
		const minutes = String(date.getMinutes()).padStart(2, '0')
		const seconds = String(date.getSeconds()).padStart(2, '0')

		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
	}
}
