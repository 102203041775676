import {
	Component,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
	input,
} from '@angular/core'
import { CommonModule } from '@angular/common'

/**
 * Use default change detection, so that changes in form are detected.
 */
@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.Default,
	templateUrl: './form-controls-footer.component.html',
	imports: [CommonModule],
	selector: 'kk-management-form-controls-footer',
})
export class ManagementFormControlsFooterComponent {
	@Output() add = new EventEmitter<void>()
	@Output() update = new EventEmitter<void>()
	@Output() delete = new EventEmitter<void>()
	deleteActive = input(true)
	updateActive = input(true)
	showDelete = input(true)
	showSubmit = input(true)
	newActive = input(true)

	updateEntity() {
		this.update.emit()
	}

	deleteEntity() {
		this.delete.emit()
	}

	addEntity() {
		this.add.emit()
	}
}
