import { Injectable } from '@angular/core'
import { User } from './user.model'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class UserDataService {
	constructor(private http: HttpClient) {}

	getCurrentUser(): Observable<User> {
		return this.http.get<User>(`persons/current`)
	}
}
