import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { News } from './news.model'

@Injectable()
export class NewsService extends EntityCollectionServiceBase<News> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('News', serviceElementsFactory)
	}
}
