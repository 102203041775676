import { ChangeDetectionStrategy, Component, input } from '@angular/core'
import { RouterLink } from '@angular/router'

@Component({
	standalone: true,
	imports: [RouterLink],
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'kk-management-back-button',
	template: `
		<a
			[routerLink]="url()"
			class="p-2 w-auto d-inline-flex flex-row align-items-center"
			style="cursor: pointer;"
			data-testid="ManagementBackButton"
		>
			<i class="fa-light fa-chevron-left"></i>
			<h3
				class="ps-3 d-none d-lg-block"
				i18n
				data-testid="ManagementSidebarBackwards"
			>
				Takaisin
			</h3>
		</a>
	`,
})
export class ManagementBackButtonComponent {
	url = input<string | null>(null)
}
