import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CalendarService } from 'src/app/features/calendar/calendar.service'
import { ToastService } from '../entity-services'
import { WorktimeWeek } from '../models'

@Injectable()
export class StampHandlerService {
	constructor(
		private http: HttpClient,
		private calendarService: CalendarService,
		private toastService: ToastService
	) {}

	updateBalances(worktimeWeek: WorktimeWeek): void {
		this.http
			.get<boolean>(
				`features/stamphandler/RunStampHandlerForPerson/` +
					worktimeWeek.personId +
					`/` +
					worktimeWeek.startDate
			)
			.subscribe((success) => {
				if (success) {
					this.calendarService.reloadEvents()
					this.calendarService.loadBalances(worktimeWeek)
					this.toastService.showSuccess(
						$localize`Saldot laskettu ` +
							new Date(worktimeWeek.startDate).toLocaleDateString() +
							$localize` alkaen.`
					)
				} else {
					this.toastService.showError(
						$localize`Saldolaskenta päätyi virheeseen!`
					)
				}
			})
	}
}
