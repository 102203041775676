import { Component, OnInit } from '@angular/core'
import { NavMenuComponent } from './nav-menu/nav-menu.component'
import { HeaderComponent } from './header/header.component'
import { CommonModule } from '@angular/common'
import { RouterOutlet } from '@angular/router'
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap'
import { NavMenuMobileComponent } from './nav-menu-mobile/nav-menu-mobile.component'
import { navMenuOptions } from './navigation-options'
import { FormDataRestorationService } from '../../../shared/components/form/form-data-restoration/form-data-restoration.service'

@Component({
	selector: 'kk-layout',
	standalone: true,
	imports: [
		CommonModule,
		RouterOutlet,
		NavMenuComponent,
		HeaderComponent,
		NavMenuMobileComponent,
		NgbNavModule,
	],
	template: `
		<div class="d-flex flex-column vh-100 overflow-hidden">
			<kk-header (toggleMobileNav)="toggleMobileNav()" class=""></kk-header>
			<div class="d-flex flex-grow-1 overflow-auto">
				<kk-nav-menu
					[options]="navMenuOptions"
					class="d-none d-lg-inline-block h-100"
				></kk-nav-menu>
				<kk-nav-menu-mobile
					*ngIf="mobileNavOpen"
					[options]="navMenuOptions"
					(closeHandler)="mobileNavOpen = false"
					class="h-100 w-100 position-fixed d-lg-none"
				></kk-nav-menu-mobile>
				<div class="overflow-hidden w-100 content">
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			kk-nav-menu-mobile {
				z-index: 999;
			}
		`,
	],
})
export class LayoutComponent implements OnInit {
	constructor(private formDataRestorationService: FormDataRestorationService) {}

	ngOnInit() {
		//Stored form data is checked here because
		// 1. Layout is rendered after auth & session-related things are finished
		// 2. importing formDataRestorationService here breaks cyclic dependency between service and modal component
		this.formDataRestorationService.checkForStoredFormData()
	}

	mobileNavOpen = false

	navMenuOptions = navMenuOptions

	toggleMobileNav() {
		this.mobileNavOpen = !this.mobileNavOpen
	}
}
