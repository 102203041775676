import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { ToastService } from '../../ngrx-store/toast/toast.service'
import { Toast, ToastVariant } from '../../ngrx-store/toast/toast.model'

@Component({
	selector: 'kk-toast',
	standalone: true,
	imports: [CommonModule],
	styleUrls: ['./toast.component.scss'],
	template: `
		<div class="kk-toast" data-testid="toast">
			<div
				class="left-border"
				[ngStyle]="{ 'border-color': variantConfig.color }"
			></div>
			<div class="content">
				<div
					*ngIf="variantConfig.icon"
					[class]="'variant-icon fa-regular fa-lg ' + variantConfig.icon"
					[ngStyle]="{ color: variantConfig.color }"
				></div>
				<div class="details">
					<div class="heading-row">
						<div class="heading">
							{{ toast.heading }}
						</div>
						<div
							class="close-icon fa-regular fa-xmark"
							(click)="closeClickHandler()"
						></div>
					</div>
					<div class="message">{{ toast.message }}</div>
					<div class="buttons" *ngIf="showButtons()">
						<div
							*ngIf="toast.showNoButton"
							class="button no"
							(click)="noClickHandler()"
							i18n
						>
							Ei
						</div>
						<div
							*ngIf="toast.showYesButton"
							class="button yes"
							(click)="yesClickHandler()"
							i18n
						>
							Kyllä
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
})
export class ToastComponent implements OnInit {
	public static readonly DEFAULT_AUTO_CLOSE_DELAY_MS = 10000
	public variantConfig: ToastVariantConfig

	@Input() toast: Toast

	constructor(private toastService: ToastService) {}

	ngOnInit(): void {
		this.variantConfig =
			VARIANT_CONFIGS.find((i) => i.variant === this.toast.variant) ??
			DEFAULT_VARIANT_CONFIG

		if (this.toast.autoClose) {
			const autoCloseDelay =
				this.toast.autoCloseDelayMs ??
				ToastComponent.DEFAULT_AUTO_CLOSE_DELAY_MS
			const interval = setInterval(() => {
				this.deleteToast()
				clearInterval(interval)
			}, autoCloseDelay)
		}
	}

	private deleteToast() {
		this.toastService.deleteToast(this.toast)
	}
	showButtons() {
		return this.toast.noClick || this.toast.yesClick
	}

	closeClickHandler() {
		this.deleteToast()
	}

	noClickHandler() {
		if (this.toast.noClick) this.toast.noClick()
		this.deleteToast()
	}

	yesClickHandler() {
		if (this.toast.yesClick) this.toast.yesClick()
		this.deleteToast()
	}
}
const DEFAULT_VARIANT_CONFIG: ToastVariantConfig = {
	variant: ToastVariant.Default,
	icon: '',
	color: '#464f54',
}
const VARIANT_CONFIGS: ToastVariantConfig[] = [
	DEFAULT_VARIANT_CONFIG,
	{
		variant: ToastVariant.Error,
		icon: 'fa-xmark',
		color: '#d43644',
	},
	{
		variant: ToastVariant.Success,
		icon: 'fa-check',
		color: '#7ec027',
	},
	{
		variant: ToastVariant.Info,
		icon: 'fa-info',
		color: '#24A0CE',
	},
	{
		variant: ToastVariant.Alert,
		icon: 'fa-triangle-exclamation',
		color: '#FFC908',
	},
]
interface ToastVariantConfig {
	variant: ToastVariant
	icon: string
	color: string
}
