import { Injectable, WritableSignal, signal } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UnsavedFormModalComponent } from './unsaved-form-modal.component'

const key = 'formDataRestoration'

export interface RestorationDataModel {
	route: string
	formData: object
}

/**
 * Service for storing a backup of form data in localStorage, in case the user accidently closes the page.
 */
@Injectable({ providedIn: 'root' })
export class FormDataRestorationService {
	/**
	 * Signal for the unsaved form data. Can be accessed by other
	 * services (FormService). The signal should be read with an effect and resetted after reading.
	 */
	restoredFormData: WritableSignal<object | null> = signal(null)

	constructor(
		private router: Router,
		private modalService: NgbModal
	) {}

	/**
	 * Function for checking the unsaved form data. Needs to be fired once
	 * when the app is loaded(after authentication etc).
	 * Displays a modal if unsaved form data is stored in localStorage.
	 */
	checkForStoredFormData() {
		const restorationData = this.getStoredFormDataFromLocalStorage()
		if (restorationData) {
			this.openModal()
		}
	}

	/**
	 * Discards unsaved data (when user clicks discard option in modal)
	 */
	discardData() {
		this.removeStoredFormDataFromLocalStorage()
	}

	/**
	 * Restores unsaved data and redirects user to the form (when user clicks restore-optio in modal)
	 */
	restoreData() {
		const restorationData = this.getStoredFormDataFromLocalStorage()
		this.removeStoredFormDataFromLocalStorage()
		if (restorationData) {
			this.restoredFormData.set(restorationData.formData)
			this.navigateToForm(restorationData.route)
		}
	}

	/**
	 * Function for clearing the signal after it has been read.
	 * The component or service should do it after reading (see formService).
	 */
	clearRestoredFormDataAfterUse() {
		this.restoredFormData.set(null)
	}

	/**
	 * Function for navigating the user to the page corresponging to the unsaved form
	 * @param route route url
	 */
	navigateToForm(route: string) {
		this.router.navigate([route])
	}

	openModal() {
		this.modalService.open(UnsavedFormModalComponent, {
			size: 'md',
			animation: true,
			centered: true,
		})
	}

	/**
	 * Function for saving new form value to localstorage after update
	 * @param formValue new value of form
	 */
	storeFormValue(formValue: object) {
		const data = {
			route: this.router.url,
			formData: formValue,
		}
		localStorage.setItem(key, JSON.stringify(data))
	}

	removeStoredFormDataFromLocalStorage() {
		localStorage.removeItem(key)
	}

	getStoredFormDataFromLocalStorage(): RestorationDataModel | null {
		const json = localStorage.getItem(key)
		const data: RestorationDataModel | null = json ? JSON.parse(json) : null
		return data
	}
}
