import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'
import { exceptions } from './exceptions'

@Directive({
	selector: '[kkIcon]',
	standalone: true,
})
export class IconDirective {
	@Input()
	set kkIcon(value: string) {
		const icon = this.mapIconToClass(value)
		this.renderer.addClass(this.el.nativeElement, icon.faStyle)
		this.renderer.addClass(this.el.nativeElement, icon.faIcon)
	}

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	mapIconToClass(icon: string) {
		const exception = exceptions.find((item) => item.icon === icon)
		return exception ?? { faStyle: 'fa-light', faIcon: 'fa-' + icon }
	}
}
