import { EntityForm } from '../../models'
import { WorktimeGroup } from 'src/app/core/ngrx-store/models'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { Component, ChangeDetectionStrategy } from '@angular/core'
import { WorktimeGroupService } from 'src/app/core/ngrx-store/worktime-group/worktime-group.service'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { toSignal } from '@angular/core/rxjs-interop'
import { WorkshiftSelectionPractice } from 'src/app/core/ngrx-store/worktime-group/worktime-group.model'
import { OvertimePractice } from 'src/app/core/ngrx-store/worktime-group/overtime-practice.model'

@Component({
	standalone: true,
	selector: 'kk-worktime-group-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi työaikaryhmä"
			i18n-title
		/>
	`,
})
export class WorktimeGroupCreateNewContentComponent implements EntityForm {
	items = toSignal(this.worktimeGroupService.entities$)

	constructor(private worktimeGroupService: WorktimeGroupService) {}

	model: Partial<WorktimeGroup> = {
		description: '',
		overtimePracticeAll: true,
		overtimePracticeDefault: OvertimePractice.Flex,
		overtimePracticeFlex: true,
		overtimePracticePaid: true,
		overtimePracticeIgnore: true,
		overtimePracticeVacation: true,
		overtimePracticeHourByHour: true,
		workshiftSelectionOrder: [WorkshiftSelectionPractice.WorkShiftPlan],
	}

	fields: FormlyFieldConfig[] = [
		{
			key: 'name',
			type: 'input',
			props: {
				label: $localize`Nimi`,
				required: true,
			},
		},
	]

	onSubmit() {
		this.worktimeGroupService.add({ ...this.model }, { isOptimistic: false })
	}
}
