import { HttpParams } from '@angular/common/http'
import { DefaultDataService } from '@ngrx/data'
import { map, expand, reduce, EMPTY, Observable } from 'rxjs'

/**
 * Response type for paginated endpoints
 */
export interface PaginationResult<T> {
	totalCount: number
	items: T[]
}

/**
 * Service extending the `DefaultDataService` for paginated endpoints.
 */
export class PaginationDataService<T> extends DefaultDataService<T> {
	/**
	 * Get page of entity data from a paginated endpoint
	 * @param pageNumber needs to be > 0
	 * @param pageSize size of the page defaults to 100
	 * @returns Observable of the page results
	 */
	getPage(
		pageNumber: number,
		pageSize = 100,
		params?: HttpParams
	): Observable<PaginationResult<T>> {
		let httpParams = params ?? new HttpParams()
		httpParams = httpParams
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize)

		return super
			.execute('GET', this.entitiesUrl, undefined, { params: httpParams })
			.pipe(map((result: unknown) => result as PaginationResult<T>))
	}

	/**
	 * Get all entities from a paginated endpoint
	 * @param pageSize size of the page defaults to 100
	 * @returns Observable list of all entities in a paginated endpoint
	 */
	getAllPages(pageSize = 100): Observable<T[]> {
		let page = 1
		return this.getPage(page, pageSize).pipe(
			expand((data) => {
				const total = data.totalCount
				const isLastPage = page * pageSize >= total
				return !isLastPage ? this.getPage(++page, pageSize) : EMPTY
			}),
			reduce((acc, result) => {
				return acc.concat(result.items)
			}, [] as T[])
		)
	}

	getAllPagesWithQuery(params: HttpParams, pageSize = 100): Observable<T[]> {
		let page = 1
		return this.getPage(page, pageSize, params).pipe(
			expand((data) => {
				const total = data.totalCount
				const isLastPage = page * pageSize >= total
				return !isLastPage ? this.getPage(++page, pageSize, params) : EMPTY
			}),
			reduce((acc, result) => {
				return acc.concat(result.items)
			}, [] as T[])
		)
	}
}
