import { Injectable, NgZone } from '@angular/core'
import {
	BABroadcastService,
	BAInteraction,
	BrowserAuthService,
} from '@lemonsoft/browser-auth'
import { Router } from '@angular/router'
import { Location } from '@angular/common'
import { Observable, map, of, switchMap, tap } from 'rxjs'

export enum AuthenticationStatus {
	InProgress,
	Failed,
	Authenticated,
}

/**
 * Class for communicating with browser-auth.
 */
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private readonly browserAuthService: BrowserAuthService,
		private readonly broadcastService: BABroadcastService,
		private router: Router,
		private location: Location,
		private zone: NgZone
	) {}

	authStatus$: Observable<AuthenticationStatus> =
		this.broadcastService.inProgress$.pipe(
			switchMap((status) => {
				if (status === BAInteraction.Completed) {
					return this.browserAuthService.hasActiveSession().pipe(
						map((hasSession) => {
							return hasSession
								? AuthenticationStatus.Authenticated
								: AuthenticationStatus.Failed
						})
					)
				} else {
					return of(AuthenticationStatus.InProgress)
				}
			}),
			tap((status) => {
				if (status === AuthenticationStatus.Authenticated) {
					this.handleRouting()
				} else if (status === AuthenticationStatus.Failed) {
					this.login()
				}
			})
		)

	currentAccountEmail$ = this.authStatus$.pipe(
		map((status) => {
			return status === AuthenticationStatus.Authenticated
				? this.browserAuthService.getCurrentAccountEmail()
				: ''
		})
	)

	private handleRouting() {
		this.zone.run(() => {
			this.router.navigateByUrl(this.location.path())
		})
	}

	/**
	 * Signs user in using provided flow
	 */
	login(): void {
		this.browserAuthService.signIn()
	}

	/**
	 * Signs user out,
	 * Does not need logout action since the hasSession will detect logout.
	 */
	logout(): void {
		localStorage.clear()
		this.browserAuthService.signOut()
	}
}
