import { NgModule } from '@angular/core'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { EntityStoreModule } from './entity-store.module'
import { HttpUrlGenerator } from '@ngrx/data'
import { CustomHttpUrlGenerator } from './custom-http-url-generator'
import { environment } from 'src/environments/environment'
import * as userEffects from './user/user.effects'
import * as toastEffects from './toast/toast.effects'
import { ViewCustomizationEffects } from './view-customization/view-customizaton.effects'
import { routerReducer } from '@ngrx/router-store'
@NgModule({
	imports: [
		StoreModule.forRoot({
			router: routerReducer,
		}),
		EffectsModule.forRoot([
			ViewCustomizationEffects,
			userEffects,
			toastEffects,
		]),
		environment.production ? [] : StoreDevtoolsModule.instrument(),
		EntityStoreModule,
	],
	providers: [
		{
			provide: HttpUrlGenerator,
			useClass: CustomHttpUrlGenerator,
		},
	],
})
export class AppStoreModule {}
