<div
	*ngrxLet="{
		accountEmail: accountEmail$ | async,
		currentEnvironment: currentEnvironment() | ngrxPush,
		loadingEnvironments: loadingEnvironments(),
		sessionStatus: sessionStatus | async,
		environmentGroups: environmentGroups$ | async
	} as vm"
	class="d-flex align-items-center justify-content-center w-100 h-100 p-3"
>
	<div
		class="window d-flex flex-column gap-3 w-100 shadow rounded-2 p-4 bg-white"
		style="max-width: 1200px"
	>
		<div>
			<p class="text-secondary" i18n>Kirjautunut käyttäjällä</p>
			<p class="fw-bold">{{ vm.accountEmail }}</p>
			<hr />
		</div>
		<div>
			<div class="grid gap-0">
				<p
					class="g-col-12 g-col-sm-6 p-3 fw-bold text-decoration-underline"
					i18n
				>
					Yrityksen nimi
				</p>
				<p
					class="d-none d-sm-block g-col-sm-3 g-col-md-2 p-3 fw-bold text-decoration-underline"
					i18n
				>
					Y-tunnus
				</p>
				<p
					class="d-none d-sm-block g-col-sm-3 g-col-md-2 p-3 fw-bold text-decoration-underline"
					i18n
				>
					Yksikkö
				</p>
				<p
					class="d-none d-md-block g-col-2 p-3 fw-bold text-decoration-underline"
					i18n
				>
					Tietokanta
				</p>
			</div>
			<div class="list-group" data-testid="environments">
				<kk-skeleton-loader
					type="text"
					width="100%"
					*ngIf="vm.loadingEnvironments"
				/>

				<div ngbAccordion>
					<div
						*ngFor="let envGroup of vm.environmentGroups"
						ngbAccordionItem
						[collapsed]="false"
					>
						<h2 ngbAccordionHeader>
							<button ngbAccordionButton>
								{{ envGroup.sqlInstance }} ({{ envGroup.environments.length }})
							</button>
						</h2>

						<div ngbAccordionCollapse>
							<div ngbAccordionBody>
								<ng-template>
									<button
										*ngFor="let env of envGroup.environments"
										data-testid="environmentButton"
										type="button"
										(click)="select(env)"
										class="list-group-item list-group-item-action w-100 p-0"
										[class.active]="isCurrentEnvironment(env)"
										style="padding-left: 17px"
									>
										<div class="grid gap-0 text-start ps-3">
											<p class="g-col-12 g-col-sm-6">
												{{ env.name }}
											</p>
											<p class="d-none d-sm-block g-col-sm-3 g-col-md-2 ms-1">
												{{ env.vat }}
											</p>
											<p class="d-none d-sm-block g-col-sm-3 g-col-md-2 ms-3">
												{{ env.unit }}
											</p>
											<p class="d-none d-md-block g-col-2 ms-3">
												{{ env.db }}
											</p>
										</div>
									</button>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-end mt-5">
			<button
				(click)="logout()"
				class="btn btn-danger"
				data-testid="environmentSelectLogout"
			>
				<p i18n>Kirjaudu ulos</p>
			</button>
		</div>
	</div>
</div>
