import {
	Injectable,
	signal,
	effect,
	WritableSignal,
	inject,
	runInInjectionContext,
	EnvironmentInjector,
	computed,
	Signal,
	untracked,
} from '@angular/core'
import { Entity, EntityService } from './models'
import { Store } from '@ngrx/store'
import { switchMap, of, distinctUntilChanged } from 'rxjs'
import { selectRouterEntityId } from 'src/app/core/ngrx-store/router/router.selectors'
import { selectEntityType, selectLoading } from './management.selectors'
import { toObservable, toSignal } from '@angular/core/rxjs-interop'
import { isEqual } from 'lodash-es'

/**
 * Service for management page and its functions.
 * The values for entity and services are injected from route resolvers.
 */
@Injectable({ providedIn: 'root' })
export class ManagementService {
	/**
	 * EntityType that matches the path. Only distinct entityTypes are selected, so creating
	 * the effect below is easier
	 */
	entityType = toSignal(
		this.store
			.select(selectEntityType)
			.pipe(distinctUntilChanged((prev, cur) => prev?.path === cur?.path)),
		{ initialValue: null }
	)

	/**
	 * Primary key of entity as string.
	 */
	entityId = this.store.selectSignal(selectRouterEntityId)

	/**
	 * Selected EntityService for loading and saving entities. Updated based on entityType.
	 */
	activeEntityService: WritableSignal<EntityService<Entity> | null> =
		signal(null)

	/**
	 * Entity from Active EntityService.
	 * Each EntityService selects this independently from the store based on entityId.
	 * Represents the currently selected entity based on entityType and entityId.
	 */
	entity: Signal<Entity | null> = computed(
		() => {
			const entityService = this.activeEntityService()
			if (entityService) {
				return entityService.entity()
			} else return null
		},
		{ equal: isEqual }
	)

	/**
	 * Items that match current entityType (mapped into SidebarItems)
	 */
	sidebarItems$ = toObservable(this.activeEntityService).pipe(
		switchMap((service) => service?.sidebarItems$ ?? of([]))
	)

	/**
	 * Loading status of currently selected entityService
	 */
	loading = this.store.selectSignal(selectLoading)

	private environmentInjector = inject(EnvironmentInjector)

	constructor(private store: Store) {
		// Load entities from server when entityType changes
		effect(() => {
			const entityType = this.entityType()
			if (entityType) {
				//entityType has been changed
				untracked(() => {
					runInInjectionContext(this.environmentInjector, () => {
						const entityService = inject(entityType.serviceToken)
						this.activeEntityService.set(entityService)
						entityService.getAll()
					})
				})
			}
		})
	}
}
