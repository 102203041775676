import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core'
import { NavMenuOption } from '../navigation-options'
import { CommonModule } from '@angular/common'
import { AttributeGuardDirective } from 'src/app/shared/directives/attribute-guard/attribute-guard.directive'
import { RouterLink } from '@angular/router'
import { UserService } from 'src/app/core/ngrx-store/entity-services'
import { LetDirective } from '@ngrx/component'

@Component({
	selector: 'kk-nav-menu-mobile',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, AttributeGuardDirective, RouterLink, LetDirective],
	styles: `
		@import 'src/app/styles/colors';

		.navbar-nav-item {
			min-height: 44px;
			display: flex;
			border-right: 4px solid transparent !important;
			border-left: 4px solid transparent !important;

			&:active {
				background: $prussian-100 !important;
				border-left: 4px solid $green-80 !important;

				.text-secondary {
					color: $green-80 !important;
				}
			}
		}
	`,
	template: `
		<ng-container
			*ngrxLet="{
				personAttributes: personAttributes()
			} as vm"
		>
			<div class="navbar bg-dark d-block w-100 h-100 p-0 shadow-sm">
				<ul class="navbar-nav w-100 d-flex flex-column">
					@for (option of options; track option.id) {
						<li
							class="navbar-nav-item mh-m bg-dark p-0 align-items-center shadow-none rounded-0"
							[attr.data-testid]="'nav-item-mobile-' + option.id"
							*kkAttributeGuard="
								option.allowAccesAttributes ?? [];
								personAttributes: vm.personAttributes
							"
						>
							<a
								[routerLink]="option.link"
								class="d-flex h-100 w-100 px-4 text-decoration-none user-select-none"
								(click)="closeHandler.emit()"
							>
								<div class="d-flex gap-2 align-items-center">
									<i
										*ngIf="option.icon"
										[ngClass]="option.icon"
										class="fa-fw text-secondary"
									></i>
									<p class="text-secondary">{{ option.title }}</p>
								</div>
							</a>
						</li>
					}
				</ul>
			</div>
		</ng-container>
	`,
})
export class NavMenuMobileComponent {
	@Input()
	options: NavMenuOption[]

	@Output()
	closeHandler = new EventEmitter<void>()

	personAttributes = this.userService.attributes
	constructor(private userService: UserService) {}
	handleClose() {
		this.closeHandler.emit()
	}
}
