import {
	Injectable,
	Signal,
	computed,
	effect,
	signal,
	untracked,
} from '@angular/core'
import { toObservable, toSignal } from '@angular/core/rxjs-interop'
import { Observable, combineLatest } from 'rxjs'
import { first, map } from 'rxjs/operators'
import {
	ExceptionDaysService,
	HolidaysService,
	UserService,
	EventService,
	EventTypeService,
	PersonService,
	ToastService,
	WorktimeGroupService,
} from 'src/app/core/ngrx-store/entity-services'
import { DateRange, RangeType, SpecialDay, CalendarEvent } from './models'
import {
	ExceptionDay,
	Holiday,
	Event,
	WorktimeDay,
	Person,
	WorktimeWeek,
	WorktimeGroup,
	OvertimePractice,
} from 'src/app/core/ngrx-store/models'
import { Store } from '@ngrx/store'
import {
	selectDate,
	selectPersonNumber,
	selectRangeType,
} from './calendar.selectors'
import { DateTime } from 'luxon'
import { ActivatedRoute, Router } from '@angular/router'
import { formatDate, MbscCalendarColor } from '@mobiscroll/angular'
import { CalendarColor } from 'src/app/core/utils/calendar-color'
import { DateUtils } from 'src/app/core/utils/date-utils'
import { WorktimeDayService } from 'src/app/core/ngrx-store/worktime-day/worktime-day.service'
import { WorktimeWeekService } from 'src/app/core/ngrx-store/worktime-week/worktime-week.service'
import { PersonRecordingMethodId } from 'src/app/core/utils/person-attribute-rules'
@Injectable({
	providedIn: 'root',
})
export class CalendarService {
	constructor(
		private eventService: EventService,
		private eventTypeService: EventTypeService,
		private userService: UserService,
		private holidaysService: HolidaysService,
		private exceptionDaysService: ExceptionDaysService,
		private workTimeDayService: WorktimeDayService,
		private workTimeWeekService: WorktimeWeekService,
		private store: Store,
		private router: Router,
		private route: ActivatedRoute,
		private personService: PersonService,
		private toastService: ToastService,
		private worktimeGroupService: WorktimeGroupService
	) {
		// When selected date changes, fetch events if necessary
		effect(
			() => {
				const date = this.date()
				this.selectedPerson()
				this.holidaysService.loadByYear(date.getFullYear())
				this.exceptionDaysService.loadByYear(date.getFullYear())
				untracked(() => this.getWorktimeDays())
				untracked(() => this.getEvents())
				untracked(() => this.getWorktimeWeeks())
			},
			{ allowSignalWrites: true }
		)
		this.eventTypeService.getAll()

		combineLatest([
			this.personService.getCurrentUserPerson(),
			this.store.select(selectPersonNumber),
			this.personService.getWorktimePersons(),
			this.worktimeGroupService.getWorktimeGroups(this.dateRange()),
		]).subscribe(
			([currentUserPerson, personNumber, worktimePersons, worktimeGroups]) => {
				const selectedPerson =
					worktimePersons.find((i) => i.number === personNumber) ??
					currentUserPerson
				this.selectedPerson.update(() => selectedPerson)
				this.currentUserPerson.update(() => currentUserPerson)
				this.worktimePersons.update(() => worktimePersons)
				this.worktimeGroups.update(() => worktimeGroups)
				this.getEvents()
			}
		)
	}

	private currentUserPerson = signal<Person | undefined>(undefined)
	public selectedPerson = signal<Person | undefined>(undefined)
	public worktimePersons = signal<Person[]>([])
	public selectedPersonIsCurrentUser = computed(
		() => this.currentUserPerson()?.id === this.selectedPerson()?.id
	)
	public worktimeGroups = signal<Map<Date, WorktimeGroup> | undefined>(
		undefined
	)

	/*
		Can current user see other persons worktimes
	*/
	public canSeeOtherPersonsWorktimes = computed(() => {
		const currentUserPersonNumber = this.currentUserPerson()?.number
		if (!currentUserPersonNumber) return false

		return this.worktimePersons().some(
			(i) => i.number !== currentUserPersonNumber
		)
	})

	private allEvents = toSignal(this.eventService.entities$)
	private selectedPersonEvents$ = toObservable(
		computed(() => {
			const selectedPersonNumber = this.selectedPerson()?.number
			const events =
				this.allEvents()?.filter(
					(i) => i.personNumber === selectedPersonNumber
				) ?? []
			return events
		})
	)

	currentUserWorktimeGroup = toSignal(this.userService.worktimeGroup$)
	overtimePracticeOptions$ = this.userService.overtimePracticeOptions$
	eventTypes$ = this.eventTypeService.entities$
	worktimeDays$ = this.workTimeDayService.entities$
	currentWorktimeWeek$ = combineLatest([
		this.workTimeWeekService.entities$,
		this.store.select(selectDate),
	]).pipe(
		map(([weeks, date]) => {
			const weekNumber = DateUtils.getIsoWeekNumber(date)
			return weeks.find((i) => i.weekNumber === weekNumber)
		})
	)
	private worktimeDays = toSignal(this.workTimeDayService.entities$)
	public selectedPersonWorktimeDays = computed(() => {
		const selectedPersonId = this.selectedPerson()?.id
		const selectedPersonWorktimeDays = this.worktimeDays()?.filter(
			(i) => i.personId === selectedPersonId
		)
		return selectedPersonWorktimeDays ?? []
	})

	private worktimeWeeks = toSignal(this.workTimeWeekService.entities$)

	currentWorktimeWeek = computed(() => {
		const personId = this.selectedPerson()?.id
		if (!personId) return undefined

		const date = this.date()
		const weekNumber = DateUtils.getIsoWeekNumber(date)
		const worktimeWeek = this.worktimeWeeks()?.find(
			(i) => i.personId === personId && i.weekNumber === weekNumber
		)
		return worktimeWeek
	})

	calendarEvents$: Observable<CalendarEvent[]> = combineLatest([
		this.selectedPersonEvents$,
		this.combineSpecialDays(
			this.holidaysService.entities$,
			this.exceptionDaysService.entities$
		),
	]).pipe(
		map(([events, specialDays]) => {
			const worktimeEvents = events.map((worktime) =>
				this.createEvent(worktime)
			)
			const specialDayEvents = specialDays.map((specialDay) =>
				this.createSpecialDayEvent(specialDay.date, specialDay.description)
			)
			return [...worktimeEvents, ...specialDayEvents]
		})
	)

	/**
	 * Create a event event for the calendar
	 * @param event
	 * @returns
	 */
	createEvent(event: Event) {
		return new CalendarEvent({
			...event,
		})
	}

	/**
	 * Create a special day event for the calendar
	 * @param specialDay
	 * @returns
	 */
	createSpecialDayEvent(date: Date, description: string) {
		return new CalendarEvent({
			start: new Date(date).toISOString(),
			end: new Date(date).toISOString(),
			allDay: true,
			header: description,
			isReadOnly: true,
			isSpecialDay: true,
			color: '#d84a56',
			overlap: true,
		})
	}

	/**
	 * Create colors for month-calendar component
	 */
	monthCalendarColors$: Observable<MbscCalendarColor[]> =
		this.combineSpecialDays(
			this.holidaysService.entities$,
			this.exceptionDaysService.entities$
		).pipe(
			map((specialDayData) =>
				specialDayData.flatMap((data) =>
					this.createMonthCalendarColor(data.date, true)
				)
			)
		)

	/**
	 * Create colors for content-calendar component
	 */
	contentCalendarColors$: Observable<MbscCalendarColor[]> =
		this.combineSpecialDays(
			this.holidaysService.entities$,
			this.exceptionDaysService.entities$
		).pipe(
			map((specialDayData) =>
				specialDayData.flatMap((data) =>
					this.createContentCalendarColor(data.date, true)
				)
			)
		)

	date = this.store.selectSignal(selectDate)
	rangeType = this.store.selectSignal(selectRangeType)
	dateRange: Signal<DateRange> = computed(() => {
		const date = this.date()
		const type = this.rangeType()

		if (type === RangeType.Week || type === RangeType.Agenda) {
			return this.getWeekRange(date)
		}
		if (type === RangeType.Month) {
			return this.getMonthRange(date)
		}

		return [date, date]
	})

	/**
	 * Helper function for calculating the date range for week
	 * @param {Date} date - The date on which the week is calculated
	 * @returns {DateRange} - First and last days (monday, sunday) of the week
	 */
	getWeekRange(date: Date): DateRange {
		const temp = new Date(date)
		let day = temp.getDay()
		day = day === 0 ? 7 : day
		const start = new Date(temp.setDate(temp.getDate() - day + 1))
		const end = new Date(temp.setDate(temp.getDate() + 6))
		return [start, end]
	}

	getMonthRange(date: Date): DateRange {
		const start = new Date(date.getFullYear(), date.getMonth(), 1)
		const end = new Date(date.getFullYear(), date.getMonth() + 1, 0)
		return [start, end]
	}

	/**
	 * Checks whether a given event overlaps with any local event
	 * @param event
	 * @returns True if there is overlap
	 */
	hasOverlap(ev: { start: string; end: string; id?: number }) {
		return this.calendarEvents$.pipe(
			map((events) =>
				events.some(
					(event) =>
						this.CreateDateFromUTCString(event.start) < new Date(ev.end) &&
						this.CreateDateFromUTCString(event.end) > new Date(ev.start) &&
						event.id !== ev.id
				)
			),
			first()
		)
	}

	isWorkdayAccepted(date: Date | string): boolean {
		const worktimeDay = this.selectedPersonWorktimeDays()?.find((i) =>
			DateUtils.datePartsEqual(i.date, date)
		)
		return worktimeDay?.isAccepted ?? false
	}

	isWorkdayReady(date: Date | string): boolean {
		const worktimeDay = this.selectedPersonWorktimeDays()?.find((i) =>
			DateUtils.datePartsEqual(i.date, date)
		)
		return worktimeDay?.isReady ?? false
	}
	/**
	 * Get events for the entire month, and the months before and after.
	 */
	private getEvents() {
		const person = this.selectedPerson()
		if (!person) return

		const currentMonth = this.date()
		const previousMonth = new Date(
			currentMonth.getFullYear(),
			currentMonth.getMonth() - 1,
			1
		)
		const nextMonth = new Date(
			currentMonth.getFullYear(),
			currentMonth.getMonth() + 1,
			1
		)

		this.eventService.loadMonthlyEvent(previousMonth, person.number)
		this.eventService.loadMonthlyEvent(currentMonth, person.number)
		this.eventService.loadMonthlyEvent(nextMonth, person.number)
	}

	public reloadEvents() {
		this.eventService.clearCache()
		this.getEvents()
	}

	getWorktimeDays() {
		const person = this.selectedPerson()
		if (!person) return
		const currentMonth = this.date()
		const previousMonth = DateUtils.getFirstDateOfPreviousMonth(currentMonth)
		const nextMonth = DateUtils.getFirstDateOfNextMonth(currentMonth)

		this.workTimeDayService.loadMonth(
			person.id,
			previousMonth.getFullYear(),
			previousMonth.getMonth()
		)
		this.workTimeDayService.loadMonth(
			person.id,
			currentMonth.getFullYear(),
			currentMonth.getMonth()
		)
		this.workTimeDayService.loadMonth(
			person.id,
			nextMonth.getFullYear(),
			nextMonth.getMonth()
		)
	}

	private getWorktimeWeeks() {
		const person = this.selectedPerson()
		if (!person) return
		const currentMonth = this.date()
		const previousMonth = DateUtils.getFirstDateOfPreviousMonth(currentMonth)
		const nextMonth = DateUtils.getFirstDateOfNextMonth(currentMonth)

		this.workTimeWeekService.loadMonth(
			person.id,
			previousMonth.getFullYear(),
			previousMonth.getMonth()
		)
		this.workTimeWeekService.loadMonth(
			person.id,
			currentMonth.getFullYear(),
			currentMonth.getMonth()
		)
		this.workTimeWeekService.loadMonth(
			person.id,
			nextMonth.getFullYear(),
			nextMonth.getMonth()
		)
	}

	setDate(date: Date = new Date()) {
		const dateParam = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')

		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { date: dateParam },
			queryParamsHandling: 'merge',
		})
	}

	setRangeType(rangeType: RangeType) {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: { view: rangeType },
			queryParamsHandling: 'merge',
		})
	}

	getMonday(d: Date): Date {
		const day = d.getDay()
		const diff = d.getDate() - ((day + 6) % 7)

		return new Date(d.setDate(diff))
	}

	/**
	 * Scroll months forward or backward in the calendar
	 * @param offset Offset in months compared to the current month
	 */
	scrollMonths(offset: number) {
		const date = new Date(this.date())
		date.setMonth(date.getMonth() + offset)
		date.setDate(1)

		this.setDate(date)
	}

	/**
	 * Scroll weeks forward or backward in the calendar
	 * @param offset Offset in weeks compared to the current week
	 */
	scrollWeeks(offset: number) {
		const date = new Date(this.getMonday(this.date()))
		date.setDate(date.getDate() + offset * 7)

		this.setDate(date)
	}

	/**
	 * Scroll days forward or backward in the calendar
	 * @param offset Offset in days compared to the current day
	 */
	scrollDays(offset: number) {
		const date = new Date(this.date())
		date.setDate(date.getDate() + offset)

		this.setDate(date)
	}

	/**
	 * Scroll forward or backward in the calendar according to the rangeType
	 * @param offset Offset compared to the current date
	 */
	scroll(offset: number) {
		switch (this.rangeType()) {
			case RangeType.Month:
				return this.scrollMonths(offset)

			case RangeType.Week:
			case RangeType.Agenda:
				return this.scrollWeeks(offset)

			case RangeType.Day:
				return this.scrollDays(offset)
		}
	}

	/**
	 * Combine holidays and exception days into one array
	 * @returns Array of SpecialDay objects
	 */
	combineSpecialDays(
		holidays: Observable<Holiday[]>,
		exceptionDays: Observable<ExceptionDay[]>
	): Observable<SpecialDay[]> {
		return combineLatest([holidays, exceptionDays]).pipe(
			map(([holidays, exceptionDays]) => {
				return this.mapToSpecialDays(holidays, exceptionDays)
			})
		)
	}

	/**
	 * Map holidays and exception days to SpecialDay objects
	 * @param holidays Array of holidays
	 * @param exceptionDays Array of exception days
	 * @returns Array of SpecialDay objects
	 */
	mapToSpecialDays(
		holidays: Holiday[],
		exceptionDays: ExceptionDay[]
	): SpecialDay[] {
		return [
			...exceptionDays.map((exceptionDay) => ({
				id: exceptionDay.id,
				date: exceptionDay.date,
				description: exceptionDay.description,
				specialDayType: exceptionDay.exceptionDayType,
			})),
			...holidays.map((holiday) => ({
				id: holiday.id,
				date: holiday.date,
				description: holiday.description,
				specialDayType: 0,
			})),
		]
	}

	/**
	 * Create colors for month-calendar component
	 */
	createMonthCalendarColor(date: Date, isSpecialDay: boolean): CalendarColor[] {
		return [
			{
				date: date,
				allDay: true,
				cellCssClass: 'holiday-monthcalendar-label',
				isSpecialDay: isSpecialDay,
			},
		]
	}

	/**
	 * Create colors for content-calendar component
	 */
	createContentCalendarColor(
		date: Date,
		isSpecialDay: boolean
	): CalendarColor[] {
		return [
			{
				start: date + 'T00:00',
				end: date + 'T23:59',
				cssClass: 'holiday-allhours-background',
				isSpecialDay: isSpecialDay,
			},
			{
				date: date,
				allDay: true,
				cellCssClass: 'holiday-monthcalendar-label',
				isSpecialDay: isSpecialDay,
			},
		]
	}

	/**
	 * Create a new date object from a UTC string
	 * @param date UTC string
	 * @returns Date object
	 */
	CreateDateFromUTCString(date: string): Date {
		const dateString = DateTime.fromISO(date, { zone: 'utc' }).toString()
		return new Date(dateString)
	}

	selectOvertimePracticeForDay(
		worktimeDay: WorktimeDay,
		overtimePractice: OvertimePractice
	) {
		this.workTimeDayService.setOvertimePracticeForDay(
			worktimeDay,
			overtimePractice
		)
	}

	public enableChangesToCalendar = computed(() => {
		return (
			this.selectedPersonIsCurrentUser() &&
			this.userService.attributes() !== null &&
			this.userService
				.attributes()!
				.some((a) => a.id === PersonRecordingMethodId.Calendar && a.isChecked)
		)
	})

	changeSelectedPerson(personNumber: number) {
		const newPerson = this.worktimePersons().find(
			(i) => i.number === personNumber
		)
		if (newPerson) {
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: { personNumber: personNumber },
				queryParamsHandling: 'merge',
			})
			this.selectedPerson.update(() => newPerson)
		}
	}

	// #region Ready/Accepted marks

	markWorktimeDayAsReady(worktimeDay: WorktimeDay) {
		this.workTimeDayService.markAsReady(worktimeDay).subscribe(() => {
			this.workTimeWeekService.reloadWeek(
				worktimeDay.personId,
				new Date(worktimeDay.date)
			)
			this.toastService.showSuccess($localize`Päivä merkitty valmiiksi`)
		})
	}

	cancelWorktimeDayReadyMark(worktimeDay: WorktimeDay) {
		this.workTimeDayService.cancelReadyMark(worktimeDay).subscribe(() => {
			this.workTimeWeekService.reloadWeek(
				worktimeDay.personId,
				new Date(worktimeDay.date)
			)
			this.toastService.showSuccess($localize`Päivän valmismerkintä peruttu`)
		})
	}

	markWorktimeDayAsAccepted(worktimeDay: WorktimeDay) {
		this.workTimeDayService.markAsAccepted(worktimeDay).subscribe(() => {
			this.workTimeWeekService.reloadWeek(
				worktimeDay.personId,
				new Date(worktimeDay.date)
			)
			this.toastService.showSuccess($localize`Päivä hyväksytty`)
		})
	}

	cancelWorktimeDayAcceptanceMark(worktimeDay: WorktimeDay) {
		this.workTimeDayService.cancelAcceptanceMark(worktimeDay).subscribe(() => {
			this.workTimeWeekService.reloadWeek(
				worktimeDay.personId,
				new Date(worktimeDay.date)
			)
			this.toastService.showSuccess($localize`Päivän hyväksyntä peruttu`)
		})
	}

	markWorktimeWeekAsReady(WorktimeWeek: WorktimeWeek) {
		this.workTimeWeekService.markAsReady(WorktimeWeek).subscribe(() => {
			this.workTimeDayService.reloadDays(
				WorktimeWeek.personId,
				new Date(WorktimeWeek.startDate),
				new Date(WorktimeWeek.endDate)
			)
			this.toastService.showSuccess($localize`Viikko merkitty valmiiksi`)
		})
	}

	cancelWorktimeWeekReadyMark(WorktimeWeek: WorktimeWeek) {
		this.workTimeWeekService.cancelReadyMark(WorktimeWeek).subscribe(() => {
			this.workTimeDayService.reloadDays(
				WorktimeWeek.personId,
				new Date(WorktimeWeek.startDate),
				new Date(WorktimeWeek.endDate)
			)
			this.toastService.showSuccess($localize`Viikon valmismerkintä peruttu`)
		})
	}

	markWorktimeWeekAsAccepted(WorktimeWeek: WorktimeWeek) {
		this.workTimeWeekService.markAsAccepted(WorktimeWeek).subscribe(() => {
			this.workTimeDayService.reloadDays(
				WorktimeWeek.personId,
				new Date(WorktimeWeek.startDate),
				new Date(WorktimeWeek.endDate)
			)
			this.toastService.showSuccess($localize`Viikko hyväksytty`)
		})
	}

	cancelWorktimeWeekAcceptedMark(WorktimeWeek: WorktimeWeek) {
		this.workTimeWeekService
			.cancelAcceptanceMark(WorktimeWeek)
			.subscribe(() => {
				this.workTimeDayService.reloadDays(
					WorktimeWeek.personId,
					new Date(WorktimeWeek.startDate),
					new Date(WorktimeWeek.endDate)
				)
				this.toastService.showSuccess($localize`Viikon hyväksyntä peruttu`)
			})
	}

	// #endregion

	/**
	 * Get the background color for a calendar event.
	 *
	 * @param {CalendarEvent} event - The event to get the background color for.
	 * @param {MbscCalendarColor[]} colors - The colors array to check for special dates.
	 * @returns {string | undefined} - The background color for the event.
	 */
	getEventBackgroundColor(
		event: CalendarEvent | undefined,
		colors: MbscCalendarColor[] = []
	): string | undefined {
		if (
			event !== undefined &&
			this.isSpecialDate(new Date(event.start), colors)
		) {
			return event.color
		}

		if (event !== undefined && event.color && event.useBackgroundColor) {
			// Add 60% opacity to the color
			return event.color + '99'
		}

		return '#FFFFFF'
	}

	/**
	 * Get the border color for a calendar event.
	 * @param event
	 * @returns
	 */
	getEventBorderColor(event: CalendarEvent | undefined): string {
		if (event?.color) {
			return event.color
		}
		return '#7DB43E'
	}

	/**
	 * Checks if a given date is a special date.
	 *
	 * @param {Date} date - The date to be checked.
	 * @returns {boolean} - Returns true if the date is special (i.e., it matches a date in the 'colors' array and is marked as 'allDay'), false otherwise.
	 */
	isSpecialDate(date: Date, colors: MbscCalendarColor[] = []): boolean {
		const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
		return (colors ?? []).some(
			(color) =>
				color.date === formattedDate && color['allDay'] && color['isSpecialDay']
		)
	}

	/**
	 * Formats a given timestamp into a specified date format.
	 *
	 * @param {string} format - The format to convert the date into.
	 * @param {Date} timestamp - The date to be formatted.
	 * @returns {string} - The formatted date.
	 */
	formatTimeStampToDate(format: string, timestamp: number): string {
		const date = new Date(timestamp)
		return formatDate(format, date)
	}

	/*
	 * Convert UTC date string to local date
	 */
	convertUTCToLocalDate(utcDateString: string | undefined | null): Date {
		if (!utcDateString) {
			return new Date()
		}

		const dateTime = DateTime.fromISO(utcDateString, { zone: 'utc' }).toLocal()
		return dateTime.toJSDate()
	}

	/*
	 * Format date to string
	 */
	formatDate(date: Date, format: string): string {
		if (isNaN(date.getTime())) {
			return ''
		}
		return formatDate(format, date)
	}
}
