import { Injectable } from '@angular/core'
import {
	EntityActionOptions,
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Stamp } from './stamp.model'
import { Observable } from 'rxjs'
import { ToastVariant } from '../toast/toast.model'
import { DateUtils } from '../../utils/date-utils'
import { StampDirectionEnum } from '../stamping-reason/stamping-reason.model'
import { ToastService } from '../toast/toast.service'

@Injectable()
export class StampService extends EntityCollectionServiceBase<Stamp> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
		private toastService: ToastService
	) {
		super('Stamp', serviceElementsFactory)
	}

	override add(
		entity: Partial<Stamp>,
		options: EntityActionOptions & { isOptimistic: false }
	): Observable<Stamp> {
		entity = { ...entity }
		const add$ = super.add(entity, options)
		const timeStampText = entity.timestamp
			? ' ' + DateUtils.getTimeOfDayHHmm(entity.timestamp)
			: ''
		const toastId = 'stamp'
		const heading =
			entity.stampDirection === StampDirectionEnum.Out
				? $localize`Ulosleimaus`
				: $localize`Sisäänleimaus`

		add$.subscribe({
			next: () => {
				const message =
					entity.stampDirection === StampDirectionEnum.Out
						? $localize`Leimattu ulos${timeStampText}`
						: $localize`Leimattu sisään${timeStampText}`
				this.toastService.deleteToastById(toastId)
				this.toastService.showSuccess(message, heading)
			},
			error: () => {
				const message = $localize`Epäonnistui${timeStampText}, haluatko koittaa uudelleen?`

				this.toastService.deleteToastById(toastId)
				this.toastService.showToast({
					heading: heading,
					message: message,
					variant: ToastVariant.Error,
					showNoButton: true,
					showYesButton: true,
					toastId: toastId,
					yesClick: () => {
						this.add(entity, options)
					},
				})
			},
		})
		return add$
	}
}
