import { Component, HostBinding, Input } from '@angular/core'

/**
 * SkeletonLoader component for displaying loading state. Component is taken from Citrus library, with few modifications.
 */
@Component({
	selector: 'kk-skeleton-loader',
	standalone: true,
	template: `
		<p
			i18n
			class="visually-hidden sr-only"
			role="status"
			data-testid="skeleton-loader"
		>
			Ladataan
		</p>
	`,
	styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
	/** Type of skeleton. Equals to style parameter in Citrus library skeleton component*/
	@Input()
	type: 'circular' | 'rectangular' | 'text' | 'circlular-small' = 'text'
	@HostBinding('class')
	public get styleType(): string {
		return `--${this.type}`
	}

	/** Sets the width of the component. Default is 100%. */
	@Input()
	@HostBinding('style.width')
	public width = '100%'
}
