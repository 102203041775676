import { Component, ChangeDetectionStrategy } from '@angular/core'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { StampingReason } from 'src/app/core/ngrx-store/models'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { textField } from 'src/app/shared/components/form/formly-field'
import { StampingReasonService } from 'src/app/core/ngrx-store/entity-services'
import { EntityForm } from '../../models'

@Component({
	standalone: true,
	selector: 'kk-stamping-reason-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi leimaussyy"
			i18n-title
		/>
	`,
})
export class StampingReasonCreateNewComponent implements EntityForm {
	constructor(private service: StampingReasonService) {}

	model: Partial<StampingReason> = {
		name: '',
	}

	fields: FormlyFieldConfig[] = [
		textField('name', $localize`Leimauksen selite`),
	]

	onSubmit() {
		this.service.add(
			{
				...this.model,
			},
			{ isOptimistic: false }
		)
	}
}
