import { createSelector } from '@ngrx/store'
import { userFeature } from './user.feature'
import { personAttributeSelector } from '../person-attribute/person-attribute.selectors'
import { worktimeGroupSelector } from '../worktime-group/worktime-group.selectors'

export const selectCurrentUserAttributes = createSelector(
	userFeature.selectUser,
	personAttributeSelector.selectEntities,
	(user, personAttributes) => {
		if (!user) return null
		const attributes = personAttributes.filter(
			(item) => item.personId === user.id && item.isChecked
		)
		return attributes
	}
)

export const selectCurrentUserWorktimeGroup = createSelector(
	userFeature.selectUser,
	worktimeGroupSelector.selectEntityMap,
	(user, worktimeGroupMap) => {
		if (!user) return null
		return worktimeGroupMap[user.worktimeGroupId] ?? null
	}
)
