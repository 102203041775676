import { EntitySelectorsFactory } from '@ngrx/data'
import { createSelector } from '@ngrx/store'
import { WorktimeGroup } from './worktime-group.model'

export const worktimeGroupSelector =
	new EntitySelectorsFactory().create<WorktimeGroup>('WorktimeGroup')

export const selectWorktimeGroupId = (worktimeGroup: WorktimeGroup) => worktimeGroup.id

export const selectWorktimeGroups = createSelector(
	worktimeGroupSelector.selectEntities,
	(worktimeGroups) => worktimeGroups
)
