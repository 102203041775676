import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	UrlTree,
	createUrlTreeFromSnapshot,
} from '@angular/router'
import { DateTime } from 'luxon'
import { RangeType } from './models'
import { getDateFromParam, getRangeTypeFromParam } from './calendar.selectors'

export const dateGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot
): true | UrlTree => {
	const dateParam = route.queryParams['date']

	if (getDateFromParam(dateParam)) {
		return true
	}

	const today = DateTime.now().toFormat('yyyy-MM-dd')
	return createUrlTreeFromSnapshot(route, [], {
		...route.queryParams,
		date: today,
	})
}

export const viewGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot
): true | UrlTree => {
	const rangeTypeParam = route.queryParams['view']

	if (getRangeTypeFromParam(rangeTypeParam)) {
		return true
	}

	return createUrlTreeFromSnapshot(route, [], {
		...route.queryParams,
		view: RangeType.Week,
	})
}
