import { Injectable, WritableSignal, signal } from '@angular/core'
import { Environment } from '../../ngrx-store/models'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs'

import * as storage from './local-storage-helpers'

export const TOKEN_REFRESH_INTERVAL = 4 * 60 * 60 * 1000 // 4 hours

export enum SessionStatus {
	Initial,
	Ready,
}

/**
 * SessionService
 * Provides signals that are related to the current session (current environment and licenseToken). These values
 * are the ones that are needed in the headers of the api calls.
 *
 * @property {WritableSignal<Environment | null} environment - Signal for keeping track of current environment
 * @property {WritableSignal<string>} licenseToken - Signal for the licenseToken.
 * @property {BehaviorSubject<SessionStatus>} sessionStatus - BehaviourSubject for status of the processes in service. This is used in license interceptors to wait if license token is being refreshed.
 *
 */
@Injectable({
	providedIn: 'root',
})
export class SessionService {
	constructor(private http: HttpClient) {}

	environment: WritableSignal<Environment | null> = signal(
		storage.getEnvFromLocalStorage()
	)

	sessionStatus = new BehaviorSubject<SessionStatus>(SessionStatus.Initial)

	/**
	 * Function for setting new environment. Saves the new value to localstorage.
	 * @param {Environment} environment - New environment
	 */
	setEnvironment(environment: Environment) {
		this.environment.set(environment)
		storage.saveEnvToLocalStorage(environment)
	}
}
