import { HttpUrlGenerator } from '@ngrx/data'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Person, PersonTemplate } from './person.model'
import { PaginationDataService } from '../paginated-data.service'
import { Observable } from 'rxjs'

@Injectable()
export class PersonDataService extends PaginationDataService<Person> {
	constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super('Person', http, httpUrlGenerator)
	}

	override getAll(): Observable<Person[]> {
		return this.getAllPages()
	}
}

@Injectable()
export class PersonTemplateDataService extends PaginationDataService<PersonTemplate> {
	constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super('PersonTemplate', http, httpUrlGenerator)
	}

	override getAll(): Observable<PersonTemplate[]> {
		return this.getAllPages()
	}
}
