<ng-container
	*ngrxLet="{
		entityType: entityType(),
		entity: entity(),
		formValue: formValue(),
		sidebarItems: sidebarItems$ | ngrxPush,
		updateActive:
			entity() !== null && !loading() && formUnsavedEdits() && formValid(),
		entityId: entityId(),
		title: title(),
		loading: loading(),
		entityName: entityName(),
		entityService: entityService()
	} as vm"
>
	<kk-view-template
		[showSidebarDesktop]="showSidebarDesktop()"
		[showSidebarMobile]="showSidebarMobile()"
		[showContentMobile]="showContentMobile()"
		[showFooter]="true"
	>
		<div
			header
			data-testid="ManagementHeaderBar"
			class="ManagementHeaderBar d-flex flex-row justify-content-between align-items-center mx-4 mb-2 my-3"
		>
			<kk-management-header-title [title]="vm.title" />
			<div class="d-flex flex-row">
				<kk-management-form-controls-header
					[show]="vm.entityType !== null"
					[actionsActive]="!!(vm.entity && vm.entityType?.actionMenuOptions)"
					[actionMenuOptions]="vm.entityType?.actionMenuOptions ?? []"
					[newActive]="canCreateNew"
					[deleteActive]="vm.entity !== null"
					[showDelete]="!entityType()?.hideDeleteButton"
					[showSubmit]="!entityType()?.hideSubmitButton"
					[updateActive]="vm.updateActive"
					(add)="addEntity()"
					(update)="updateEntity(vm.entity, vm.formValue)"
					(delete)="deleteEntity()"
					(selectAction)="openAction($event)"
				/>
			</div>
		</div>

		<div headerMobile class="d-flex flex-row gap-2 align-items-center">
			<kk-management-back-button
				*ngIf="vm.entityType"
				[url]="vm.entity !== null ? '/management/' + vm.entityType.path : '.'"
			/>
			<p>{{ vm.entityName }}</p>
		</div>

		<div
			sidebar
			class="managementSidebar d-flex flex-column h-100"
			data-testid="ManagementSidebar"
		>
			<ng-container *ngIf="vm.entityType === null; else sidebarEntitySelection">
				<kk-management-entity-type-list />
			</ng-container>

			<ng-template #sidebarEntitySelection>
				<div class="h-100 d-flex flex-column">
					<kk-management-back-button
						url="."
						class="d-none d-lg-block px-2 pb-2 my-3"
					/>
					<kk-management-entity-tree
						*ngIf="vm.entityService?.sidebarView === 'tree'"
						class="h-100 overflow-hidden"
						[items]="vm.sidebarItems ?? []"
						[activeEntityId]="vm.entityId"
						[entityType]="vm.entityType"
						[loading]="vm.loading"
						(canCreateNew)="canCreateNewChange($event)"
					/>
					<kk-management-entity-list
						*ngIf="vm.entityService?.sidebarView !== 'tree'"
						class="h-100 overflow-hidden"
						[sidebarItems]="vm.sidebarItems ?? []"
						[activeEntityId]="vm.entityId"
						[entityType]="vm.entityType"
						[loading]="vm.loading"
						(searchTermChange)="filterSearch($event)"
					/>
				</div>
			</ng-template>
		</div>

		<div content class="h-100 w-100">
			<div class="h-100 w-100 overflow-y-auto">
				<router-outlet data-testid="ManagementRouter"></router-outlet>
			</div>
		</div>

		<div footer>
			<kk-management-form-controls-footer
				class="d-flex d-lg-none justify-content-end bg-prussian-90"
				*ngIf="vm.entityType"
				[deleteActive]="vm.entity !== null"
				[updateActive]="vm.updateActive"
				[showDelete]="!entityType()?.hideDeleteButton"
				[showSubmit]="!entityType()?.hideSubmitButton"
				(update)="updateEntity(vm.entity, vm.formValue)"
				[newActive]="canCreateNew"
				(delete)="deleteEntity()"
				(add)="addEntity()"
			/>
		</div>
	</kk-view-template>
</ng-container>
