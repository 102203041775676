import { ChangeDetectionStrategy, Component } from '@angular/core'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { DimensionLevelService } from 'src/app/core/ngrx-store/entity-services'
import { DimensionLevel } from 'src/app/core/ngrx-store/models'
import { selectRouterEntityId } from 'src/app/core/ngrx-store/router/router.selectors'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { EntityForm } from '../../models'
import { dimensionLevelSelector } from 'src/app/core/ngrx-store/dimension-level/dimension-level.selectors'

@Component({
	standalone: true,
	selector: 'kk-dimension-level-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi kohdetaso"
			i18n-title
		/>
	`,
})
export class DimensionLevelCreateNewContentComponent implements EntityForm {
	constructor(private service: DimensionLevelService) {}

	activeEntityId = this.service.store.selectSignal(selectRouterEntityId)()
	model: Partial<DimensionLevel> = {}
	fields: FormlyFieldConfig[] = [
		{
			type: 'input',
			props: {
				label: $localize`Pääryhmä`,
				disabled: true,
				attributes: {
					'data-testid': 'parentDimensionLevelSelect',
				},
			},
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					const entityMap = this.service.store.selectSignal(
						dimensionLevelSelector.selectEntityMap
					)()
					const activeEntity = entityMap[Number(this.activeEntityId)]
					field.formControl?.setValue(activeEntity?.description)
				},
			},
			expressions: {
				hide: () => !this.activeEntityId,
			},
		},
		{
			key: 'description',
			type: 'input',
			props: {
				label: 'Nimi',
				required: true,
			},
		},
	]

	onSubmit() {
		this.service.add(
			{
				...this.model,
				parentId: this.activeEntityId ? Number(this.activeEntityId) : undefined,
			},
			{ isOptimistic: false }
		)
	}
}
