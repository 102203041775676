import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
	MergeStrategy,
} from '@ngrx/data'
import { WorktimeWeek } from './worktime-week.model'
import { HttpClient } from '@angular/common/http'
import { DateUtils } from '../../utils/date-utils'
import { UserService } from '../entity-services'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class WorktimeWeekService extends EntityCollectionServiceBase<WorktimeWeek> {
	private _loadedMonths: { personId: number; year: number; month: number }[] =
		[]

	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
		userService: UserService,
		private httpClient: HttpClient
	) {
		super('WorktimeWeek', serviceElementsFactory)
		userService.getCurrentUser().subscribe(() => {
			this._loadedMonths = []
		})
	}

	public getWeekByDayId(worktimeDayId: number): Observable<WorktimeWeek> {
		return this.httpClient.get<WorktimeWeek>(
			'WorktimeWeek/getWeekByDayId/' + worktimeDayId
		)
	}

	public markAsAccepted(worktimeWeek: WorktimeWeek) {
		// Update instantly so ui feels more responsive
		this.updateOneInCache({
			...worktimeWeek,
			isReady: true,
			isAccepted: true,
			isOpen: false,
			canCancelAcceptedMark: false,
			canCancelReadyMark: false,
			canMarkAsAccepted: false,
			canMarkAsReady: false,
		})

		const request$ = this.httpClient.post<WorktimeWeek | undefined>(
			'WorktimeWeek/markAsAccepted',
			worktimeWeek.id
		)

		request$.subscribe((response) => {
			if (response) {
				this.updateOneInCache(response)
			}
		})

		return request$
	}

	public cancelAcceptanceMark(worktimeWeek: WorktimeWeek) {
		// Update instantly so ui feels more responsive
		this.updateOneInCache({
			...worktimeWeek,
			isReady: false,
			isAccepted: false,
			isOpen: true,
			canCancelAcceptedMark: false,
			canCancelReadyMark: false,
			canMarkAsAccepted: false,
			canMarkAsReady: false,
		})

		const request$ = this.httpClient.post<WorktimeWeek | undefined>(
			'WorktimeWeek/cancelAcceptedMark',
			worktimeWeek.id
		)

		request$.subscribe((response) => {
			if (response) {
				this.updateOneInCache(response)
			}
		})

		return request$
	}
	public markAsReady(worktimeWeek: WorktimeWeek) {
		// Update instantly so ui feels more responsive.
		this.updateOneInCache({
			...worktimeWeek,
			isReady: true,
			isAccepted: false,
			isOpen: false,
			canCancelAcceptedMark: false,
			canCancelReadyMark: false,
			canMarkAsAccepted: false,
			canMarkAsReady: false,
		})

		const request$ = this.httpClient.post<WorktimeWeek | undefined>(
			'WorktimeWeek/markAsReady',
			worktimeWeek.id
		)

		request$.subscribe((response) => {
			if (response) {
				this.updateOneInCache(response)
			}
		})

		return request$
	}

	public cancelReadyMark(worktimeWeek: WorktimeWeek) {
		// Update instantly so ui feels more responsive.
		this.updateOneInCache({
			...worktimeWeek,
			isReady: true,
			isAccepted: false,
			isOpen: false,
			canCancelAcceptedMark: false,
			canCancelReadyMark: false,
			canMarkAsAccepted: false,
			canMarkAsReady: false,
		})

		const request$ = this.httpClient.post<WorktimeWeek | undefined>(
			'WorktimeWeek/cancelReadyMark',
			worktimeWeek.id
		)

		request$.subscribe((response) => {
			if (response) {
				this.updateOneInCache(response)
			}
		})

		return request$
	}

	public reloadWeek(personId: number, date: Date) {
		const queryParams = {
			personId: personId,
			startDate: DateUtils.toIsoStringDatePartOnly(date),
			endDate: DateUtils.toIsoStringDatePartOnly(date),
		}
		this.getWithQuery(queryParams, {
			mergeStrategy: MergeStrategy.OverwriteChanges,
		}).subscribe((worktimeWeeks) => {
			this.updateOneInCache(worktimeWeeks[0])
		})
	}

	public loadMonth(personId: number, year: number, monthIndex: number) {
		if (
			this._loadedMonths.some(
				(i) =>
					i.personId === personId && i.year === year && i.month === monthIndex
			)
		) {
			return
		}

		this._loadedMonths.push({
			personId: personId,
			year: year,
			month: monthIndex,
		})

		const start = new Date(year, monthIndex, 1)
		const end = DateUtils.getLastDateOfMonth(start)
		const queryParams = {
			personId: personId,
			startDate: DateUtils.toIsoStringDatePartOnly(start),
			endDate: DateUtils.toIsoStringDatePartOnly(end),
		}
		this.getWithQuery(queryParams)
	}
}
