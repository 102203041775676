export enum WidgetType {
	List = 1,
	Card = 2,
	Graph = 3,
	Custom = 101,
}

export interface Widget {
	id: number
	drilldownUrl: string
	userHasLicense: boolean
}
export interface ListWidget extends Widget {
	description: string
	drilldownUrlExternal: string
	drilldownDetail: string
}

export interface CardWidget extends Widget {
	title: string
	subtitles: string[]
	notificationType: string
	notificationText: string
	color: string
	icon: string
}

export interface WidgetInfo {
	id: number
	description: string
	validLicenses: string[]
	userHasLicense: boolean
	widgetGroup: number
}
export interface ExtendedWidgetInfo extends WidgetInfo {
	type: WidgetType
	cols?: number
	rows?: number
	allowAccessAttributes?: number[]
}

export interface CustomWidgetInfo extends ExtendedWidgetInfo {
	allowAccessAttributes: number[]
}
