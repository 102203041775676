import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Event } from './event.model'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { userFeature } from '../user/user.feature'
import { DateUtils } from '../../utils/date-utils'
import { UserService } from '../entity-services'

@Injectable()
export class EventService extends EntityCollectionServiceBase<Event> {
	private _loadedMonths: {
		personNumber: number
		year: number
		month: number
	}[] = []

	constructor(
		private http: HttpClient,
		userService: UserService,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('Event', serviceElementsFactory)

		userService.getCurrentUser().subscribe(() => {
			this._loadedMonths = []
		})
	}

	getStampInStatus(): Observable<boolean> {
		const user = this.store.selectSignal(userFeature.selectUser)
		return this.http.get<boolean>(`events/stamp-in-status/` + user()?.number)
	}

	getOpenEvent(): Observable<Event | null> {
		const user = this.store.selectSignal(userFeature.selectUser)
		return this.http.get<Event>(`events/get-open-event/` + user()?.number)
	}

	loadMonthlyEvent(date: Date, personNumber: number) {
		const year = date.getFullYear()
		const monthIndex = date.getMonth()

		if (
			this._loadedMonths.some(
				(i) =>
					i.personNumber === personNumber &&
					i.year === year &&
					i.month === monthIndex
			)
		) {
			return
		}

		this._loadedMonths.push({
			personNumber: personNumber,
			year: year,
			month: monthIndex,
		})

		const start = DateUtils.getFirstDateOfMonth(date).toISOString()
		const end = DateUtils.getLastDateOfMonth(date).toISOString()

		const queryParams = {
			personNumber: personNumber,
			start,
			end,
		}

		this.getWithQuery(queryParams).subscribe({
			error: () => (this._loadedMonths = []),
		})
	}
	override clearCache(): void {
		this._loadedMonths = []
		super.clearCache()
	}
}
