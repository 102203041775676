import { Component } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { UnsavedEditsService } from './unsaved-edits.service'

@Component({
	selector: 'kk-unsaved-edits-modal',
	standalone: true,
	template: `
		<div class="modal-header p-3 pb-3" style="border: none;">
			<h2 class="modal-title" id="modal-basic-title" i18n>
				Hylätäänkö muutokset?
			</h2>
			<button
				type="button"
				class="btn-close"
				aria-label="Sulje"
				i18n-aria-label
				(click)="activeModal.dismiss('Cross click')"
			></button>
		</div>
		<div class="modal-body px-3 py-0">
			<p i18n>Sinulla on tallentamattomia muutoksia, hylätäänkö muutokset?</p>
		</div>
		<div class="modal-footer px-3 pb-2" style="border: none;">
			<button
				type="button"
				class="btn btn-secondary"
				(click)="activeModal.close('Close click')"
				i18n
			>
				Peru
			</button>
			<button
				type="submit"
				class="btn btn-success"
				(click)="confirmExit()"
				i18n
				ngbAutofocus
			>
				Ok
			</button>
		</div>
	`,
})
export class UnsavedEditsModalComponent {
	constructor(
		public activeModal: NgbActiveModal,
		public unsavedEditsService: UnsavedEditsService
	) {}

	confirmExit() {
		this.activeModal.close('Submit')
		this.unsavedEditsService.confirmExitPage()
	}
}
