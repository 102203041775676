import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'

import { RouterModule } from '@angular/router'
/**
 * Dropdown menu containing a list of titled actions.
 */

@Component({
	selector: 'kk-nav-menu-option',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
	styles: `
		@import 'src/app/styles/colors';

		.expanded {
			width: 200px;
		}

		.navbar-nav-item {
			outline: none;	
			border-right: 4px solid transparent !important;
			border-left: 4px solid transparent !important;
			height: 2.75rem;
			&::after {
				content: none;
			}

			&:hover {
				background: $prussian-100 !important;
				.text-secondary {
					color: $white !important;
				}
			}

			&:active {
				background: $prussian-100 !important;
				border-left: 4px solid $green-80 !important;

				.text-secondary {
					color: $green-80 !important;
				}
				
			}
		}
	`,
	template: `<div class="navbar-nav-item" [class.expanded]="expanded">
		<a
			class="w-100 h-100 d-flex gap-2 align-items-center border-0 px-3 shadow-none text-decoration-none"
			type="button"
			[id]="id"
			[routerLink]="link"
			[title]="title"
		>
			<div class="d-flex gap-2 align-items-center">
				<i *ngIf="icon" [ngClass]="icon" class="fa-fw text-secondary"></i>
				<p *ngIf="expanded" class="text-secondary m-0">{{ title }}</p>
			</div>
		</a>
	</div> `,
})
export class NavMenuOptionComponent {
	/** Title for dropdown button */
	@Input()
	title?: string

	/** Id for labeling */
	@Input()
	id: string

	@Input()
	link?: string

	@Input()
	icon?: string

	@Input()
	expanded?: boolean = true

	@Output()
	closeHandler = new EventEmitter<void>()
}
