import { FormlyFieldConfig } from '@ngx-formly/core'
import { Observable } from 'rxjs'

/**
 * Create a text input field which is required
 * @param {string} [className = 'g-col-md-6 g-col-12']
 * @returns {FormlyFieldConfig}
 */
export const requiredTextField = (
	key: string,
	label: string,
	className = 'g-col-md-6 g-col-12'
): FormlyFieldConfig => {
	return createFormlyFieldConfig(key, label, 'input', className, true)
}

/**
 * Create a text input field which is not required
 * @param {string} [className = 'g-col-md-6 g-col-12']
 * @returns {FormlyFieldConfig}
 */
export const textField = (
	key: string,
	label: string,
	className = 'g-col-md-6 g-col-12'
): FormlyFieldConfig => {
	return createFormlyFieldConfig(key, label, 'input', className, false)
}

/**
 * Create a checkbox field which is not required
 * @param {string} [className = 'g-col-12']
 * @returns {FormlyFieldConfig}
 */
export const checkboxField = (
	key: string,
	label: string,
	className = 'g-col-12'
): FormlyFieldConfig => {
	return createFormlyFieldConfig(key, label, 'checkbox', className, false)
}

/**
 * Create a time input field which is not required
 * @param {string} [className = 'g-col-12 g-col-lg-6']
 * @returns {FormlyFieldConfig}
 */
export const timeField = (
	key: string,
	label: string,
	className = 'g-col-12 g-col-lg-6'
): FormlyFieldConfig => {
	return createFormlyFieldConfig(key, label, 'time', className, false)
}

/**
 * Create a time input field which is required
 * @param {string} [className = 'g-col-12 g-col-lg-6']
 * @returns {FormlyFieldConfig}
 */
export const requiredTimeField = (
	key: string,
	label: string,
	className = 'g-col-12 g-col-lg-6'
): FormlyFieldConfig => {
	return createFormlyFieldConfig(key, label, 'time', className, true)
}

/**
 * Create an empty row
 */
export const emptyRow = {
	className: 'g-col-12',
	template: '<hr/>',
}

/**
 * Create a formly field based on the parameters. Used mainly as base
 * for the more specific field creater-functions.
 * @param {string} [type = 'input'] - creates a text field by default
 * @param {string} [className = 'g-col-md-6 g-col-12']
 * @param {boolean} [required = false]
 * @returns {FormlyFieldConfig}
 */
export const createFormlyFieldConfig = (
	key: string,
	label: string,
	type = 'input',
	className = 'g-col-md-6 g-col-12',
	required = false
): FormlyFieldConfig => {
	return {
		key: key,
		type: type,
		className: className,
		props: {
			label: label,
			required: required,
		},
	}
}

/**
 * Create a select field based on the parameters
 */
export const selectField = (
	key: string,
	label: string,
	options?: unknown[] | Observable<unknown[]>,
	className = 'g-col-12',
	required = false
): FormlyFieldConfig => ({
	key,
	type: 'select',
	className,
	props: {
		label,
		required,
		options,
	},
})

/**
 * Create a number field based on the parameters.
 * @param {string} [addonText = '']
 * @param {string} [className = 'g-col-12']
 * @param {boolean} [required = false]
 * @returns {FormlyFieldConfig}
 */
export const numberField = (
	key: string,
	label: string,
	addonText = '',
	className = 'g-col-12',
	required = false
): FormlyFieldConfig => {
	return {
		key: key,
		type: 'input',
		className: className,
		props: {
			label: label,
			required: required,
			type: 'number',
			addonLeft: {
				text: addonText,
			},
		},
	}
}
