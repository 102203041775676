import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Environment } from 'src/app/core/ngrx-store/environment/environment.model'

@Injectable()
export class EnvironmentService extends EntityCollectionServiceBase<Environment> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('Environment', serviceElementsFactory)
	}
}
