import { EntitySelectorsFactory } from '@ngrx/data'
import { createSelector } from '@ngrx/store'
import { WorkShift } from './workshift.model'

export const workShiftSelector =
	new EntitySelectorsFactory().create<WorkShift>('WorkShift')

export const selectWorkShiftId = (
	workShift: WorkShift
) => workShift.id

export const selectWorkShifts = createSelector(
	workShiftSelector.selectEntities,
	(workShift) => workShift
)
