import { AuthService } from 'src/app/core/services/auth/auth.service'
import { Component, EventEmitter, Output } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { LetDirective, PushPipe } from '@ngrx/component'
import { UserService } from 'src/app/core/ngrx-store/entity-services'
import {
	DropdownOption,
	HeaderDropdownComponent,
} from './header-dropdown/header-dropdown.component'

/**
 * The upper bar of the layout containing the logo,
 * navigation menu toggle and other essential functions.
 */

@Component({
	selector: 'kk-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		HeaderDropdownComponent,
		LetDirective,
		PushPipe,
	],
})
export class HeaderComponent {
	currentUser$ = this.userService.getCurrentUser()

	constructor(
		private authService: AuthService,
		private router: Router,
		private userService: UserService
	) {}

	/** Toggle for hamburger menu */
	@Output()
	toggleMobileNav = new EventEmitter<void>()

	settingsOptions: DropdownOption[] = [
		{
			title: $localize`Asetukset`,
			action: () => {
				this.router.navigate(['settings']).catch((err) => alert(err))
			},
		},
	]

	profileOptions: DropdownOption[] = [
		{
			title: $localize`Kirjaudu ulos`,
			action: () => {
				this.logout()
			},
		},
	]

	public logout(): void {
		this.authService.logout()
	}

	mobileNavToggleHandler() {
		this.toggleMobileNav.emit()
	}
}
