import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { ExceptionDay } from './exception-day.model'

@Injectable()
export class ExceptionDaysService extends EntityCollectionServiceBase<ExceptionDay> {
	private _loadedYears = new Set<number>()
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('ExceptionDays', serviceElementsFactory)
	}

	loadByYear(year: number): void {
		if (this._loadedYears.has(year)) return

		this._loadedYears.add(year)
		this.getWithQuery({ year }).subscribe({
			error: () => this._loadedYears.delete(year),
		})
	}
}
