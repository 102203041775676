import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { LetDirective, PushPipe } from '@ngrx/component'
import { ToastComponent } from './toast.component'
import { ToastService } from '../../ngrx-store/toast/toast.service'
import { animate, style, transition, trigger } from '@angular/animations'

@Component({
	selector: 'kk-toast-list',
	standalone: true,
	imports: [CommonModule, PushPipe, LetDirective, ToastComponent],
	styleUrls: ['./toast-list.component.scss'],
	animations: [
		trigger('openClose', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateX(-100%)' }),
				animate(
					'250ms ease-in',
					style({ opacity: 1, transform: 'translateX(0)' })
				),
			]),
			transition(':leave', [
				style({ opacity: 1, transform: 'translateX(0)' }),
				animate(
					'250ms ease-in',
					style({ opacity: 0, transform: 'translateX(100%)' })
				),
			]),
		]),
	],
	template: `
		<ng-container
			*ngrxLet="{
				toasts: toasts$ | ngrxPush
			} as vm"
		>
			<div *ngIf="vm.toasts?.length" class="kk-toast-list" @openClose>
				<ng-container *ngFor="let toast of vm.toasts">
					<div @openClose><kk-toast [toast]="toast" /></div>
				</ng-container>
			</div>
		</ng-container>
	`,
})
export class ToastListComponent {
	toasts$ = this.toastService.toasts$

	constructor(private toastService: ToastService) {}
}
