import { EntitySelectorsFactory } from '@ngrx/data'
import { Person, PersonTemplate } from './person.model'
import { createSelector } from '@ngrx/store'

export const personSelector = new EntitySelectorsFactory().create<Person>(
	'Person'
)

/**
 * Returns the primary key of person.
 * @param {Person} person
 * @returns {number} - Primary key of the given Person-entity
 */
export const selectPersonNumber = (person: Person) => person.number

export const selectPersons = createSelector(
	personSelector.selectEntities,
	(person) => person
)

export const personTemplateSelector =
	new EntitySelectorsFactory().create<PersonTemplate>('PersonTemplate')
