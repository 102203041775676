import {
	Directive,
	Input,
	HostBinding,
	ElementRef,
	ViewContainerRef,
	ApplicationRef,
	ComponentRef,
	createComponent,
} from '@angular/core'
import { LoadingComponent } from '../../components/loading/loading.component'

/**
 * Directive for rendering spinner on top of an element.
 * The spinner is positioned absolutely and position-relative class is set to the parent element.
 */
@Directive({
	selector: '[kkLoading]',
	standalone: true,
})
export class LoadingDirective {
	@HostBinding('style.position')
	hostPosition = 'relative'

	componentRef: ComponentRef<LoadingComponent>

	@Input() set kkLoading(loading: boolean) {
		if (loading) {
			this.componentRef.setInput('visible', true)
		} else {
			this.componentRef.setInput('visible', false)
		}
	}

	constructor(
		public viewContainerRef: ViewContainerRef,
		private applicationRef: ApplicationRef,
		private elementRef: ElementRef
	) {
		const environmentInjector = this.applicationRef.injector
		const host = this.elementRef.nativeElement

		this.componentRef = createComponent(LoadingComponent, {
			hostElement: host,
			environmentInjector,
		})
		this.applicationRef.attachView(this.componentRef.hostView)
		this.componentRef.changeDetectorRef.detectChanges()
	}
}
