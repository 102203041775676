import { Injectable } from '@angular/core'
import {
	DefaultHttpUrlGenerator,
	HttpResourceUrls,
	Pluralizer,
} from '@ngrx/data'
import { EntityHttpResourceUrls } from '@ngrx/data/src/dataservices/http-url-generator'

@Injectable()
export class CustomHttpUrlGenerator extends DefaultHttpUrlGenerator {
	constructor(pluralizer: Pluralizer) {
		super(pluralizer)
	}

	private entityToUrlMap = {
		ViewCustomization: 'view-customizations',
		CardWidget: 'widgets/cards',
		ListWidget: 'widgets/lists',
		AvailableCardWidget: 'widgets/cards/available',
		AvailableListWidget: 'widgets/lists/available',
		News: 'news',
		Environment: 'environments',
		Person: 'persons',
		PersonTemplate: 'persons/templates',
		PersonAttribute: 'person-attributes',
		WorktimeGroup: 'worktime-groups',
		WorktimeGroupHistory: 'worktime-group-history',
		Event: 'Events',
		WorkShift: 'workshifts',
		WorktimeDay: 'WorktimeDay',
		WorktimeWeek: 'WorktimeWeek',
		WorktimeRegulation: 'worktime-regulations',
		EventType: 'event-types',
		Dimension: 'dimensions',
		DimensionLevel: 'dimension-levels',
		StampingReason: 'stamping-reasons',
		Holidays: 'holidays',
		ExceptionDays: 'exception-days',
		CompanyLocations: 'company-locations',
		Supervisors: 'supervisors',
		Stamp: 'stamp',
	}

	private mapFromEntries(): EntityHttpResourceUrls {
		return Object.entries(this.entityToUrlMap)
			.map(([entity, url]) => ({ [entity]: CustomHttpUrlGenerator.urls(url) }))
			.reduce((acc, current) => ({ ...acc, ...current }), {})
	}

	override knownHttpResourceUrls: EntityHttpResourceUrls = this.mapFromEntries()

	private static urls(entityPath: string): HttpResourceUrls {
		return {
			collectionResourceUrl: `${entityPath}/`,
			entityResourceUrl: `${entityPath}/`,
		}
	}
}
