import { HttpUrlGenerator, QueryParams } from '@ngrx/data'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Event } from './event.model'
import { PaginationDataService } from '../paginated-data.service'
import { Observable } from 'rxjs'

@Injectable()
export class EventDataService extends PaginationDataService<Event> {
	constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super('Event', http, httpUrlGenerator)
	}

	override getWithQuery(params: QueryParams): Observable<Event[]> {
		const httpParams = new HttpParams({ fromObject: params })

		return this.getAllPagesWithQuery(httpParams)
	}
}
