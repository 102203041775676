import {
	ActivatedRouteSnapshot,
	CanDeactivateFn,
	RouterStateSnapshot,
} from '@angular/router'
import { UnsavedEditsService } from 'src/app/shared/components/form/unsaved-edits/unsaved-edits.service'
import { Component, inject } from '@angular/core'
import { FormDataRestorationService } from 'src/app/shared/components/form/form-data-restoration/form-data-restoration.service'

export const unsavedEditsGuard: CanDeactivateFn<Component> = (
	component: Component,
	currentRoute: ActivatedRouteSnapshot,
	currentState: RouterStateSnapshot,
	nextState: RouterStateSnapshot
): boolean => {
	const unsavedEditsService = inject(UnsavedEditsService)
	const formDataRestorationService = inject(FormDataRestorationService)
	if (unsavedEditsService.passGuard()) {
		formDataRestorationService.removeStoredFormDataFromLocalStorage()
		return true
	}

	if (unsavedEditsService.unsavedEdits()) {
		unsavedEditsService.setTargetUrl(nextState.url)
		unsavedEditsService.open()
		return false
	}
	return true
}
