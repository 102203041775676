export enum DateSelectionType {
	SingleDay = 'SingleDay',
	Weekdays = 'Weekdays',
	SpecialDays = 'SpecialDays',
}
export const DateSelectionTypeLabels = {
	SingleDay: $localize`Yksittäinen päivämäärä`,
	Weekdays: $localize`Valitut viikonpäivät`,
	SpecialDays: $localize`Poikkeuspäivät`,
}
