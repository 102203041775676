<div class="d-flex gap-1" data-testid="ManagementFormControlsFooter">
	<button
		(click)="addEntity()"
		type="button"
		class="btn kk-btn-prussian"
		[disabled]="!newActive()"
		data-testid="ManagementFormControlsAddFooter"
	>
		<div class="d-flex flex-column align-items-center">
			<i class="fa-light fa-plus p-0"></i>
			<p i18n>Uusi</p>
		</div>
	</button>

	@if (showDelete()) {
		<button
			(click)="deleteEntity()"
			[disabled]="!deleteActive()"
			type="button"
			class="btn kk-btn-prussian"
			data-testid="ManagementFormControlsDeleteFooter"
		>
			<div class="d-flex flex-column align-items-center">
				<i class="fa-light fa-trash p-0"></i>
				<p i18n>Poista</p>
			</div>
		</button>
	}

	@if (showSubmit()) {
		<button
			(click)="updateEntity()"
			[disabled]="!updateActive()"
			type="submit"
			class="btn kk-btn-prussian"
			data-testid="ManagementFormControlsSubmitFooter"
		>
			<div class="d-flex flex-column align-items-center">
				<i class="fa-light fa-save p-0"></i>
				<p i18n>Tallenna</p>
			</div>
		</button>
	}
</div>
