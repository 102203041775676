import { getRouterSelectors } from '@ngrx/router-store'
import { createSelector } from '@ngrx/store'

export const selectRouterEntityTypePath = createSelector(
	getRouterSelectors().selectRouteParam('entityTypePath'),
	(entityTypePath) => entityTypePath ?? ''
)

export const selectRouterEntityId = createSelector(
	getRouterSelectors().selectRouteParam('entityId'),
	(entityId) => entityId ?? ''
)

export const routerSelectDate = getRouterSelectors().selectQueryParam('date')
export const routerSelectRangeType =
	getRouterSelectors().selectQueryParam('view')

export const routerSelectPageNumber =
	getRouterSelectors().selectQueryParam('pageNumber')

export const routerSelectPageSize =
	getRouterSelectors().selectQueryParam('pageSize')

export const routerSelectSearchTerm =
	getRouterSelectors().selectQueryParam('searchTerm')
export const routerSelectPersonNumber =
	getRouterSelectors().selectQueryParam('personNumber')
