export enum StampType {
	Normal = 'Normal',
	Lunch = 'Lunch',
	Break = 'Break',
}

export const stampTypeLabels: { [key in StampType]: string } = {
	Normal: $localize`Normaali`,
	Lunch: $localize`Lounas`,
	Break: $localize`Tauko`,
}

export enum DayTypeOption {
	OnlyWorkingDays = 'OnlyWorkingDays',
	Weekdays = 'Weekdays',
	AllDays = 'AllDays',
}

export const dayTypeOptionLabels: {
	[key in DayTypeOption]: string
} = {
	[DayTypeOption.OnlyWorkingDays]: $localize`Vain työpäivät`,
	[DayTypeOption.Weekdays]: $localize`Kaikki arkipäivät`,
	[DayTypeOption.AllDays]: $localize`Kaikki päivät`,
}

export enum StampDirectionEnum {
	In = 'In',
	Out = 'Out',
}

export const stampDirectionEnumLabels: { [key in StampDirectionEnum]: string } =
	{
		In: $localize`Sisään`,
		Out: $localize`Ulos`,
	}

export interface StampingReason {
	id: number
	name: string
	enableInQuickStamp: boolean
	orderNumber: number
	stampType: StampType
	public: boolean
	enabled: boolean
	stampDirection: StampDirectionEnum
	endsWorkShift: boolean
	endsWorkShift_gracePeriod: number
	worktimeGroups: number[]
	stampAutomaticFillSettings: StampAutomaticFillSetting[]
}

export enum StampAutomaticFillType {
	ToWorkShiftEndTime = 'ToWorkShiftEndTime',
	ToWorkShiftLength = 'ToWorkShiftLength',
	FillEmptyWorkShifts = 'FillEmptyWorkShifts',
	ToWorkShiftStartTime = 'ToWorkShiftStartTime',
	FillGaps = 'FillGaps',
	ToNextStampIn = 'ToNextStampIn',
	FromWorkShiftEnd = 'FromWorkShiftEnd',
	FromWorkShiftLength = 'FromWorkShiftLength',
	MinutesAfterStamp = 'MinutesAfterStamp',
}

export const StampAutomaticFillTypeLabels: {
	[key in StampAutomaticFillType]: string
} = {
	ToWorkShiftEndTime: $localize`Vuoron loppuun`,
	ToWorkShiftLength: $localize`Vuoron pituuteen`,
	FillEmptyWorkShifts: $localize`Tyhjät vuorot`,
	ToWorkShiftStartTime: $localize`Vuoron alkuun`,
	FillGaps: $localize`Kirjausten välit`,
	ToNextStampIn: $localize`Vuoron alusta sisäänleimaukseen`,
	FromWorkShiftEnd: $localize`Edellisen vuoron loppuun`,
	FromWorkShiftLength: $localize`Edellisen vuoron pituuteen`,
	MinutesAfterStamp: $localize`Minuuttimäärä`,
}

export interface StampAutomaticFillSetting {
	id: number | undefined | null
	stampingReasonId: number | undefined | null
	stampAutomaticFillType: StampAutomaticFillType
	eventTypeId: number | undefined | null
	stampInReasonId: number | undefined | null
	stampOutReasonId: number | undefined | null
	minutesAfterStamp: number | undefined | null
	enabled: boolean
}
