import { Injectable, InjectionToken, inject } from '@angular/core'
import { map } from 'rxjs'
import { DimensionLevelService } from 'src/app/core/ngrx-store/entity-services'
import { DimensionLevel } from 'src/app/core/ngrx-store/models'
import {
	EntityService,
	EntityType,
	SidebarItem,
	entitySelectorFactory,
} from '../../models'
import { DimensionLevelCreateNewContentComponent } from './dimension-level-create-new.component'
import { dimensionLevelSelector } from 'src/app/core/ngrx-store/dimension-level/dimension-level.selectors'

export const selectDimensionLevelEntity = entitySelectorFactory(
	dimensionLevelSelector
)

@Injectable({ providedIn: 'root' })
export class DimensionLevelEntityService extends EntityService<DimensionLevel> {
	override mapEntityToSidebarItem = (dimensionLevel: DimensionLevel) => {
		return {
			id: dimensionLevel.id.toString(),
			title: dimensionLevel.description,
		}
	}

	constructor(dimensionLevelService: DimensionLevelService) {
		super(dimensionLevelService, selectDimensionLevelEntity)

		this.sidebarItems$ = this.entityCollectionService.filteredEntities$.pipe(
			map((entities) => [
				{
					// This is the static root node of the sidebar tree, containing just the title
					id: '',
					title: dimensionLevelEntityType.title,
					// Children contain the top-level entities and so on
					children: entities
						.filter((level) => !level.parentId)
						.map((level) => this.buildSidebarTree(level, entities)),
				},
			])
		)
		this.sidebarView = 'tree'
		this.formlyFields = [
			{
				fieldGroupClassName: 'grid align-items-end',
				fieldGroup: [
					{
						key: 'description',
						type: 'input',
						className: 'g-col-12',
						props: {
							label: $localize`Nimi`,
						},
					},
				],
			},
		]
	}

	/**
	 * Builds a hierarchical nested sidebar object from a list of items
	 * @param dimensionLevel The root item
	 * @param entities The list of items
	 * @returns The nested sidebar item
	 */
	private buildSidebarTree(
		dimensionLevel: DimensionLevel,
		entities: DimensionLevel[]
	): SidebarItem {
		const children = entities.filter(
			(child) => child.parentId === dimensionLevel.id
		)

		return {
			...this.mapEntityToSidebarItem(dimensionLevel),
			children: children.map((child) => this.buildSidebarTree(child, entities)),
		}
	}
}

export const dimensionLevelEntityType: EntityType = {
	title: $localize`Kohdetasot`,
	path: 'dimension-levels',
	serviceToken: new InjectionToken<DimensionLevelEntityService>(
		'dimension-levels',
		{
			factory: () => inject(DimensionLevelEntityService),
		}
	),
	createNewContentComponent: DimensionLevelCreateNewContentComponent,
}
