import { HttpUrlGenerator } from '@ngrx/data'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginationDataService } from '../paginated-data.service'
import { Observable } from 'rxjs'
import { WorkShift } from './workshift.model'

@Injectable()
export class WorkShiftDataService extends PaginationDataService<WorkShift> {
	constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super('WorkShift', http, httpUrlGenerator)
	}

	override getAll(): Observable<WorkShift[]> {
		return this.getAllPages()
	}
}
