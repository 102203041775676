import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { ListWidget } from './widget.model'

@Injectable()
export class ListWidgetService extends EntityCollectionServiceBase<ListWidget> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('ListWidget', serviceElementsFactory)
	}
}
