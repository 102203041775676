<div class="d-flex flex-column h-100 px-3 my-3 my-lg-4 justify-content-start">
	<h3 class="mb-lg-4 mb-3" i18n data-testid="ManagementSidebarTitle">
		Valinnat
	</h3>

	<div class="list-group" data-testid="ManagementSidebarEntityTypeList">
		<a
			class="list-group-item list-group-item-action p-0"
			*ngFor="let entityType of entityTypeMap | keyvalue"
			[routerLink]="['.', entityType.key]"
			data-testid="ManagementSidebarEntityTypeItem"
		>
			<div
				class="w-100 p-3 pe-4 d-flex flex-row align-items-center justify-content-between"
			>
				<p>
					{{ entityType.value.title }}
				</p>
				<i class="fa-light fa-chevron-right fs-5"></i>
			</div>
		</a>
	</div>
</div>
