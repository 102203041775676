import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	standalone: true,
	imports: [CommonModule],
	selector: 'kk-management-header-title',
	template: `
		<h1 class="pt-3 pt-lg-4 pb-3" data-testid="ManagementHeaderBarTitle" i18n>
			{{ title }}
		</h1>
	`,
})
export class ManagementHeaderTitleComponent {
	@Input() title: string
}
