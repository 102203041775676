import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { SessionService } from '../../services/session/session.service'
import { Environment } from '../../ngrx-store/models'

/**
 * Environment Interceptor
 * Adds environment parameters to the headers of requests
 */
@Injectable()
export class EnvironmentInterceptor implements HttpInterceptor {
	constructor(private sessionService: SessionService) {}
	intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		const environment = this.sessionService.environment()
		const modifiedReq = this.setEnvironmentHeaders(req, environment)
		return next.handle(modifiedReq)
	}

	setEnvironmentHeaders(
		req: HttpRequest<unknown>,
		environment: Environment | null
	) {
		let headers = req.headers
		if (environment) {
			headers = headers
				.set('LemonVat', environment.vat)
				.set('LemonUnit', environment.unit)
				.set('LemonInstanceId', environment.instanceId)
		}
		const modifiedReq = req.clone({
			headers,
		})
		return modifiedReq
	}
}
