import { EntitySelectorsFactory } from '@ngrx/data'
import { createSelector } from '@ngrx/store'
import { EventType } from './event-type.model'

export const eventTypeSelector = new EntitySelectorsFactory().create<EventType>(
	'EventType'
)

export const selectEventTypeId = (eventType: EventType) => eventType.id

export const selectEventTypes = createSelector(
	eventTypeSelector.selectEntities,
	(eventTypes) => eventTypes
)
