import { Injectable, WritableSignal, signal } from '@angular/core'

@Injectable()
export class ManagementFormService {
	formValue: WritableSignal<object> = signal({})
	unsavedEdits: WritableSignal<boolean> = signal(false)
	valid: WritableSignal<boolean> = signal(true)

	updateFormValue(formValue: object) {
		this.formValue.set(formValue)
	}

	updateUnsavedEdits(value: boolean) {
		this.unsavedEdits.set(value)
	}

	updateValid(value: boolean) {
		this.valid.set(value)
	}
}
