import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { EnvironmentService } from 'src/app/core/ngrx-store/entity-services'
import { Environment } from 'src/app/core/ngrx-store/models'
import { NgFor, NgIf, AsyncPipe, JsonPipe, CommonModule } from '@angular/common'
import {
	environmentSelectId,
	environmentSelector,
	selectEnvironments,
} from 'src/app/core/ngrx-store/environment/environment.selectors'
import { LetDirective, PushPipe } from '@ngrx/component'
import { SessionService } from 'src/app/core/services/session/session.service'
import { AuthService } from 'src/app/core/services/auth/auth.service'
import { LoadingDirective } from 'src/app/shared/directives/loading/loading.directive'
import { SkeletonLoaderComponent } from 'src/app/shared/components/skeleton/skeleton-loader.component'
import { Observable, map } from 'rxjs'
import { groupBy } from 'lodash-es'
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'kk-environment-select',
	templateUrl: './environment-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./environment-select.component.scss'],
	standalone: true,
	imports: [
		NgbAccordionModule,
		NgFor,
		NgIf,
		AsyncPipe,
		JsonPipe,
		PushPipe,
		LetDirective,
		CommonModule,
		LoadingDirective,
		SkeletonLoaderComponent,
	],
})
export class EnvironmentSelectComponent {
	environments$ = this.store.select(selectEnvironments)

	environmentGroups$: Observable<EnvironmentGroup[]> = this.environments$.pipe(
		map((envList) => {
			const grouped = groupBy(envList, 'sqlInstance')
			return Object.entries(grouped).map(
				([key, value]) =>
					({
						sqlInstance: key,
						environments: value,
					}) as EnvironmentGroup
			)
		})
	)

	loadingEnvironments = this.store.selectSignal(
		environmentSelector.selectLoading
	)
	currentEnvironment = this.sessionService.environment
	accountEmail$ = this.authService.currentAccountEmail$
	sessionStatus = this.sessionService.sessionStatus

	constructor(
		private environmentService: EnvironmentService,
		private sessionService: SessionService,
		private authService: AuthService,
		private store: Store
	) {
		this.environmentService.getAll()
	}

	select(environment: Environment) {
		if (this.isCurrentEnvironment(environment)) {
			return
		}

		this.sessionService.setEnvironment(environment)
	}

	isCurrentEnvironment(environment: Environment): boolean {
		const currentEnvironment = this.currentEnvironment()
		if (!currentEnvironment) return false

		return (
			environmentSelectId(currentEnvironment) ===
			environmentSelectId(environment)
		)
	}

	logout() {
		this.authService.logout()
	}
}

export interface EnvironmentGroup {
	sqlInstance: string
	environments: Environment[]
}
