import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { WorktimeGroupHistory } from './worktime-group-history.model'

@Injectable()
export class WorktimeGroupHistoryService extends EntityCollectionServiceBase<WorktimeGroupHistory> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('WorktimeGroupHistory', serviceElementsFactory)
	}
}
