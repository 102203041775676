import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Signal,
	computed,
	effect,
} from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule, NgComponentOutlet } from '@angular/common'
import { ManagementHeaderTitleComponent } from './components/header-title/header-title.component'
import { ManagementFormControlsHeaderComponent } from './components/form-controls-header/form-controls-header.component'
import { ManagementFormControlsFooterComponent } from './components/form-controls-footer/form-controls-footer.component'
import { ManagementService } from './management.service'
import { LetDirective, PushPipe } from '@ngrx/component'
import { ManagementBackButtonComponent } from './components/back-button/back-button.component'
import { ManagementEntityListComponent } from './components/entity-list/entity-list.component'
import { ManagementEntityTypeListComponent } from './components/entity-type-list/entity-type-list.component'
import { ViewTemplateComponent } from 'src/app/core/components/view-template/view-template.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DeleteEntityModalComponent } from './components/delete-entity-modal.component'
import { ManagementFormService } from './management-form.service'
import { FormDataRestorationService } from 'src/app/shared/components/form/form-data-restoration/form-data-restoration.service'
import { Entity } from './models'
import { ManagementEntityTreeComponent } from './components/entity-tree/entity-tree.component'

@Component({
	selector: 'kk-management',
	templateUrl: './management.component.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CommonModule,
		ManagementHeaderTitleComponent,
		ManagementFormControlsHeaderComponent,
		ManagementFormControlsFooterComponent,
		ManagementBackButtonComponent,
		ManagementEntityListComponent,
		ManagementEntityTypeListComponent,
		ManagementEntityTreeComponent,
		ViewTemplateComponent,
		RouterModule,
		LetDirective,
		PushPipe,
		NgComponentOutlet,
	],
	providers: [ManagementFormService],
	styleUrls: ['./management.component.scss'],
})
export class ManagementComponent {
	constructor(
		private managementService: ManagementService,
		private managementFormService: ManagementFormService,
		private modalService: NgbModal,
		private formDataRestorationService: FormDataRestorationService,
		private cdr: ChangeDetectorRef
	) {
		effect(
			() => {
				const service = this.entityService()
				if (service) service.setFilter()
			},
			{
				allowSignalWrites: true,
			}
		)
	}

	entityId = this.managementService.entityId
	entity = this.managementService.entity
	entityType = this.managementService.entityType
	formValue = this.managementFormService.formValue
	formUnsavedEdits = this.managementFormService.unsavedEdits
	formValid = this.managementFormService.valid
	entityService = this.managementService.activeEntityService
	sidebarItems$ = this.managementService.sidebarItems$
	loading = this.managementService.loading
	canCreateNew = true

	showSidebarDesktop = computed(() => {
		if (this.entityType()?.useCustomView) {
			return false
		}
		return true
	})

	showSidebarMobile = computed(() => {
		const entityType = this.entityType()
		if (entityType?.useCustomView) {
			//Hide sidebar if user has custom View defined in router
			return false
		}
		if (!entityType) {
			// Show sidebar if entityType is not selected in mobile
			return true
		}
		return this.entity() === null //show sidebar if user is selecting entity, hide it if user is editing
	})

	showContentMobile = computed(() => {
		const entityType = this.entityType()
		if (entityType?.useCustomView) {
			//Hide sidebar if user has custom View defined in router
			return true
		}
		if (!entityType) {
			// hide content if user is selecting entityType in mobile
			return false
		}
		return this.entity() !== null //hide content if user is selecting entity, show it if user is editing
	})

	defaultTitle = $localize`Hallintanäkymä`
	title = computed(() => this.entityType()?.title ?? this.defaultTitle)
	entityName: Signal<string> = computed(() => {
		const entity = this.entity()
		if (entity && 'name' in entity) {
			return entity.name!
		}
		return this.title()
	})

	filterSearch(searchTerm: string) {
		this.entityService()?.setFilter(searchTerm)
	}

	updateEntity(entity: Entity | null, formValue: object) {
		const service = this.entityService()
		if (!service || !entity) {
			return
		}
		const updatedData = { ...entity, ...formValue }
		// Some entity services, like PersonEntityService, require manual subscription for update.
		service.update(updatedData).subscribe()
		this.formDataRestorationService.removeStoredFormDataFromLocalStorage()
	}

	deleteEntity() {
		const entity = this.entity()
		const service = this.entityService()?.entityCollectionService

		if (!service || !entity) {
			return
		}

		const selectedId = service.selectId(entity)
		const modalRef = this.modalService.open(DeleteEntityModalComponent)
		modalRef.componentInstance.delete = () => service.delete(selectedId)
	}

	addEntity() {
		const entityType = this.entityType()
		if (!entityType) return
		this.modalService.open(entityType.createNewContentComponent)
	}

	openAction(index: number) {
		const action = this.entityType()?.actionMenuOptions?.[index]
		if (!action) return
		this.modalService.open(action.component)
	}

	canCreateNewChange(value: boolean) {
		this.canCreateNew = value
		this.cdr.detectChanges()
	}
}
