import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Person, PersonTemplate } from './person.model'
import { filter, Observable, Subject, switchMap } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { userApiActions } from '../user/user.actions'
import { userFeature } from '../user/user.feature'

@Injectable()
export class PersonService extends EntityCollectionServiceBase<Person> {
	private worktimePersons$ = new Subject<Person[]>()
	private worktimePersonsLoaded = false

	constructor(
		private http: HttpClient,
		serviceElementsFactory: EntityCollectionServiceElementsFactory
	) {
		super('Person', serviceElementsFactory)
	}

	/*
		Returns all person whose worktimes can be accessed
	*/
	public getWorktimePersons(): Observable<Person[]> {
		if (!this.worktimePersonsLoaded) {
			this.worktimePersonsLoaded = true
			this.http
				.get<Person[]>('persons/worktimePersons')
				.subscribe((persons) => {
					this.addManyToCache(persons)
					this.worktimePersons$.next(persons)
				})
		}

		return this.worktimePersons$
	}

	public getCurrentUserPerson(): Observable<Person> {
		this.store.dispatch(userApiActions.loadUser())
		return this.store
			.select(userFeature.selectUser)
			.pipe(filter(Boolean))
			.pipe(switchMap((user) => this.getByKey(user.number)))
	}
}

@Injectable()
export class PersonTemplateService extends EntityCollectionServiceBase<PersonTemplate> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('PersonTemplate', serviceElementsFactory)
	}
}
