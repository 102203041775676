import { EntityService, EntityType, entitySelectorFactory } from '../../models'
import { InjectionToken, inject, Injectable } from '@angular/core'
import {
	EventTypeService,
	WorktimeGroupService,
} from 'src/app/core/ngrx-store/entity-services'
import {
	eventTypeSelector,
	selectEventTypeId,
} from 'src/app/core/ngrx-store/event-type/event-type.selectors'
import { map, of } from 'rxjs'
import {
	flexHandlingLabels,
	usableOnHourDayEventLables,
	eventTypeCategoryLabels,
	EventType,
} from 'src/app/core/ngrx-store/event-type/event-type.model'
import { EventTypeCreateNewContentComponent } from './event-type-create-new.component'
import { textField } from 'src/app/shared/components/form/formly-field'

export const selectEventTypeEntity = entitySelectorFactory(eventTypeSelector)

@Injectable({ providedIn: 'root' })
export class EventTypeEntityService extends EntityService<EventType> {
	override mapEntityToSidebarItem = (eventType: EventType) => {
		return {
			id: selectEventTypeId(eventType).toString(),
			title: of(eventType.name),
			subtitle: of(
				$localize`Tyyppi: ` +
					eventTypeCategoryLabels[eventType.eventTypeCategory]
			),
		}
	}

	constructor(
		private eventTypeService: EventTypeService,
		private worktimeGroupService: WorktimeGroupService
	) {
		super(eventTypeService, selectEventTypeEntity)
		this.formlyFields = [
			{
				fieldGroupClassName: 'grid align-items-end',
				fieldGroup: [
					textField('name', $localize`Nimi`, 'g-col-12'),
					{
						key: 'eventTypeCategory',
						type: 'select',
						className: 'g-col-12',
						props: {
							label: $localize`Kirjauslajityyppi`,
							options: Object.entries(eventTypeCategoryLabels).map(
								([key, label]) => ({
									value: key,
									label,
								})
							),
						},
					},
					{
						key: 'usableOnHourDayEventSelection',
						type: 'select',
						className: 'g-col-12',
						props: {
							label: $localize`Käytössä tuntikirjauksella`,
							options: Object.entries(usableOnHourDayEventLables).map(
								([key, label]) => ({
									value: key,
									label,
								})
							),
						},
					},
					{
						key: 'flexHandling',
						type: 'select',
						className: 'g-col-12',
						props: {
							label: $localize`Liukumakäsittely`,
							options: Object.entries(flexHandlingLabels).map(
								([key, label]) => ({
									value: key,
									label,
								})
							),
						},
					},
					{
						key: 'worktimeGroups',
						type: 'multiselect',
						className: 'g-col-12',
						props: {
							label: $localize`Työaikaryhmät`,
							multiple: true,
							items: this.worktimeGroupService.entities$.pipe(
								map((worktimeGroups) =>
									worktimeGroups.map((group) => ({
										value: group.id,
										label: group.name,
									}))
								)
							),
						},
						hooks: {
							onInit: () => this.worktimeGroupService.getAll(),
						},
					},
					{
						key: 'color',
						type: 'color',
						className: 'g-col-12',
						props: {
							label: $localize`Väri kalenterissa`,
						},
					},
					{
						key: 'useBackgroundColor',
						type: 'checkbox',
						className: 'g-col-12',
						props: {
							label: $localize`Taustaväri käytössä kirjaukselle`,
						},
					},
				],
			},
		]
	}
}

export const eventTypeEntityType: EntityType = {
	title: $localize`Kirjauslajit`,
	path: 'event-types',
	serviceToken: new InjectionToken<EventTypeEntityService>('event-types', {
		factory: () => inject(EventTypeEntityService),
	}),
	createNewContentComponent: EventTypeCreateNewContentComponent,
}
