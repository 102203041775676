import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { tap } from 'rxjs'
import { CardWidgetService, ListWidgetService } from '../entity-services'
import { EntityAction, EntityOp, ofEntityOp, ofEntityType } from '@ngrx/data'
import { ViewElement } from '../models'

@Injectable()
export class ViewCustomizationEffects {
	constructor(
		private actions$: Actions<EntityAction>,
		private cardWidgetService: CardWidgetService,
		private listWidgetService: ListWidgetService
	) {}

	viewCustomizationLoadSuccessCards$ = createEffect(
		() =>
			this.actions$.pipe(
				ofEntityType('ViewCustomization'),
				ofEntityOp(EntityOp.QUERY_BY_KEY_SUCCESS),
				tap((action) => {
					this.loadCardWidgets(action.payload.data?.viewElements)
				})
			),
		{ dispatch: false }
	)

	viewCustomizationLoadSuccessLists$ = createEffect(
		() =>
			this.actions$.pipe(
				ofEntityType('ViewCustomization'),
				ofEntityOp(EntityOp.QUERY_BY_KEY_SUCCESS),
				tap((action) => {
					this.loadListWidgets(action.payload.data?.viewElements)
				})
			),
		{ dispatch: false }
	)

	loadCardWidgets(viewElements: ViewElement[] = []) {
		const cards: string[] = []
		viewElements.forEach((element: ViewElement) => {
			element.type == 2 && cards.push(element.id.toString())
		})
		if (cards.length) {
			this.cardWidgetService.getWithQuery({ ids: cards })
		}
	}

	loadListWidgets(viewElements: ViewElement[] = []) {
		const lists: string[] = []
		viewElements.forEach((element: ViewElement) => {
			element.type == 1 && lists.push(element.id.toString())
		})
		if (lists.length) {
			this.listWidgetService.getWithQuery({ ids: lists })
		}
	}
}
