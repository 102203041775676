export enum DayOfWeek {
	Monday = 'Monday',
	Tuesday = 'Tuesday',
	Wednesday = 'Wednesday',
	Thursday = 'Thursday',
	Friday = 'Friday',
	Saturday = 'Saturday',
	Sunday = 'Sunday',
}

export const dayOfWeekLabels: { [key in DayOfWeek]: string } = {
	Monday: $localize`Maanantai`,
	Tuesday: $localize`Tiistai`,
	Wednesday: $localize`Keskiviikko`,
	Thursday: $localize`Torstai`,
	Friday: $localize`Perjantai`,
	Saturday: $localize`Lauantai`,
	Sunday: $localize`Sunnuntai`,
}
