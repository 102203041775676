import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { ViewCustomization } from 'src/app/core/ngrx-store/models'

@Injectable()
export class ViewCustomizationService extends EntityCollectionServiceBase<ViewCustomization> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('ViewCustomization', serviceElementsFactory)
	}
}
