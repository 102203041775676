import { Attribute } from '../attribute/attribute.model'

export enum PersonAttributeGroup {
	Role = 'Role',
	RecordingMethod = 'RecordingMethod',
	Permission = 'Permission',
}

export interface PersonAttribute extends Attribute {
	groupId: PersonAttributeGroup
	personId: number
}
