import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { CompanyLocation } from './company-location.model'
import { Observable } from 'rxjs'

@Injectable()
export class CompanyLocationsService extends EntityCollectionServiceBase<CompanyLocation> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('CompanyLocations', serviceElementsFactory)
	}
	override getAll(): Observable<CompanyLocation[]> {
		return this.getWithQuery({ lang: 'FIN' })
	}
}
