import { EntitySelectorsFactory } from '@ngrx/data'
import { createSelector } from '@ngrx/store'
import { ViewCustomization } from './view-customization.model'

export const viewCustomizationSelector =
	new EntitySelectorsFactory().create<ViewCustomization>('ViewCustomization')

export const viewCustomizationSelectId = (i: ViewCustomization) =>
	viewCustomizationCreateId(i.personNumber, i.guid, i.controlKey)

export const viewCustomizationCreateId = (
	personNumber: number,
	guid: string,
	controlKey: string
) => `${personNumber}/${guid}/${controlKey}`

export const selectViewCustomizations = createSelector(
	viewCustomizationSelector.selectEntities,
	(items) => items
)
