import { Route } from '@angular/router'
import { ManagementComponent } from './management.component'
import { unsavedEditsGuard } from 'src/app/shared/components/form/unsaved-edits/unsaved-edits.guard'
import { managementFeatureGuard } from './guards/management-feature-guard/management-feature.guard'
import entityTypeProviderFactory, {
	ENTITY_TYPE_MAP_TOKEN,
} from './entity-type.provider'
import { dimensionsUrlMatcher } from './configurations/dimension/dimension'

export const managementRoutes: Route[] = [
	{
		path: '',
		providers: [
			{ provide: ENTITY_TYPE_MAP_TOKEN, useFactory: entityTypeProviderFactory },
		],
		component: ManagementComponent,
		children: [
			{
				path: '',
				loadComponent: () =>
					import(
						'./pages/entity-type-selection-page/entity-type-selection-page.component'
					).then((x) => x.EntityTypeSelectionPageComponent),
			},
			{
				matcher: dimensionsUrlMatcher,
				canActivate: [managementFeatureGuard],
				loadComponent: () =>
					import(
						'./configurations/dimension/dimension-entity-selection.component'
					).then((x) => x.DimensionEntitySelectionComponent),
			},
			{
				path: ':entityTypePath',
				canActivate: [managementFeatureGuard],
				loadComponent: () =>
					import(
						'./pages/entity-selection-page/entity-selection-page.component'
					).then((x) => x.EntitySelectionPageComponent),
			},
			{
				path: ':entityTypePath/:entityId',
				canActivate: [managementFeatureGuard],
				canDeactivate: [unsavedEditsGuard],
				loadComponent: () =>
					import(
						'./pages/entity-editing-page/entity-editing-page.component'
					).then((x) => x.EntityEditingPageComponent),
			},
		],
	},
]
