import { createActionGroup, props } from '@ngrx/store'
import { Toast } from './toast.model'

export const toastActions = createActionGroup({
	source: 'Toast',
	events: {
		addToast: props<{ toast: Toast }>(),
		deleteToast: props<{ toast: Toast }>(),
		deleteToastById: props<{ toastId: string | number }>(),
	},
})
