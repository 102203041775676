import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { userFeature } from '../user/user.feature'
import { WorktimeWeek } from '../worktime-week/worktime-week.model'
import { Store } from '@ngrx/store'
import { CalendarService } from 'src/app/features/calendar/calendar.service'
import { ToastService } from '../entity-services'

@Injectable()
export class StampHandlerService {
	constructor(
		private http: HttpClient,
		private store: Store,
		private calendarService: CalendarService,
		private toastService: ToastService
	) {}

	updateBalances(worktimeWeek: WorktimeWeek): void {
		const user = this.store.selectSignal(userFeature.selectUser)
		this.http
			.get<boolean>(
				`features/stamphandler/RunStampHandlerForPerson/` +
					user()?.number +
					`/` +
					worktimeWeek.startDate
			)
			.subscribe((success) => {
				if (success) {
					this.calendarService.reloadEvents()
					this.toastService.showSuccess(
						$localize`Saldot laskettu ` +
							new Date(worktimeWeek.startDate).toLocaleDateString() +
							$localize` alkaen.`
					)
				} else {
					this.toastService.showError(
						$localize`Saldolaskenta päätyi virheeseen!`
					)
				}
			})
	}
}
