export interface TimeCounterBalance {
	id: number
	timeCounterId: number
	realizedWorkshiftId: number
	balance: number
	balanceChange: number
	ignoredBalanceChange: number
	balanceLocked: boolean
	timeCounterType: TimeCounterType
	balanceDate: string
	balanceWeekNumber: number
}

export enum TimeCounterType {
	/// <summary>
	/// Other
	/// </summary>
	Other = 'Other',

	/// <summary>
	/// WorktimeFlex
	/// </summary>
	WorktimeFlex = 'WorktimeFlex',

	/// <summary>
	/// Worktime
	/// </summary>
	Worktime = 'Worktime',

	/// <summary>
	/// Regular worktime
	/// </summary>
	RegularWorktime = 'RegularWorktime',

	/// <summary>
	/// Additional work
	/// </summary>
	AdditionalWork = 'AdditionalWork',

	/// <summary>
	/// Overtime work
	/// </summary>
	OvertimeWork = 'OvertimeWork',

	/// <summary>
	/// Paid
	/// </summary>
	Paid = 'Paid',

	/// <summary>
	/// Vacation
	/// </summary>
	Vacation = 'Vacation',

	/// <summary>
	/// Ignore
	/// </summary>
	Ignore = 'Ignore',

	/// <summary>
	/// HourByHour
	/// </summary>
	HourByHour = 'HourByHour',

	/// <summary>
	/// WeeklyWorkTime
	/// </summary>
	WeeklyWorktime = 'WeeklyWorktime',
}
