import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { hasAccess } from 'src/app/core/guards/attribute-guard/attribute.guard'
import { PersonAttribute } from 'src/app/core/ngrx-store/models'

/**
 * Directive for hiding components that require specific attribute to view.
 * Give an array of the attribute ids as parameter, which allow access to viewing the component.
 */
@Directive({
	selector: '[kkAttributeGuard]',
	standalone: true,
})
export class AttributeGuardDirective {
	private allowAccesAttributes: number[] | undefined | null
	private personAttributes: PersonAttribute[] | undefined | null

	constructor(
		private template: TemplateRef<unknown>,
		private viewContainer: ViewContainerRef
	) {}

	@Input() set kkAttributeGuard(
		allowAccesAttributes: number[] | undefined | null
	) {
		this.allowAccesAttributes = allowAccesAttributes
		this.renderTemplate()
	}

	@Input() set kkAttributeGuardPersonAttributes(
		personAttributes: PersonAttribute[] | null
	) {
		this.personAttributes = personAttributes
		this.renderTemplate()
	}

	private renderTemplate() {
		if (
			this.personAttributes &&
			this.allowAccesAttributes &&
			hasAccess(this.personAttributes, this.allowAccesAttributes)
		) {
			this.viewContainer.clear()
			this.viewContainer.createEmbeddedView(this.template)
		} else {
			this.viewContainer.clear()
		}
	}
}
