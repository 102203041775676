import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { StampingReason } from './stamping-reason.model'

@Injectable()
export class StampingReasonService extends EntityCollectionServiceBase<StampingReason> {
	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('StampingReason', serviceElementsFactory)
	}
}
